import { graphql, StaticQuery, Link } from 'gatsby';
import React, { useContext } from 'react';
import CartContext from '../context/CartContext';
import * as style from '../modules/checkout.module.scss';
import ConversionLayout from './conversion-layout';
import Process, { steps } from './repeatable/process';
import Reassurance from './static/rea';

function Layout({ data, children }) {
  const { cart: shoppingCart } = useContext(CartContext);

  return (
    <ConversionLayout banner={!shoppingCart.coupon}>
      <div className={style.main}>
        {/** Cart Header */}
        <div className={style.header}>
          <Process currentStep={steps.INFORMATIONS} />
        </div>

        <div className={`${style.checkout_wrapper}`}>
          {/* Forms */}
          <div className={`${style.forms__wrapper}`}>{children}</div>
        </div>
      </div>

      <Reassurance />
    </ConversionLayout>
  );
}

export default function (props) {
  return (
    <StaticQuery
      render={(data) => <Layout data={data} {...props} />}
      query={graphql`
        {
          pagePanierYaml {
            essentielCoaching {
              publicURL
            }
            essentielMenus {
              publicURL
            }
            essentielSupplements {
              publicURL
            }
            coaching {
              publicURL
            }
            antiox {
              publicURL
            }
            equilibre {
              publicURL
            }
            arrow {
              publicURL
            }
          }
        }
      `}
    />
  );
}
