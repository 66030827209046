// extracted by mini-css-extract-plugin
export var benefits_image = "food-supplements-module--benefits_image--eup8k";
export var benefits_subtitle = "food-supplements-module--benefits_subtitle--lVF3U";
export var benefits_title = "food-supplements-module--benefits_title--F3UA-";
export var composition_wrapper = "food-supplements-module--composition_wrapper--faW9U";
export var composition_wrapper__essentiel = "food-supplements-module--composition_wrapper__essentiel--Ui152";
export var contents_icon = "food-supplements-module--contents_icon--EtRBE";
export var contents_item__benefits = "food-supplements-module--contents_item__benefits--bxauo";
export var contents_items = "food-supplements-module--contents_items--vjBNF";
export var contents_single_item = "food-supplements-module--contents_single_item--KEeCe";
export var detailed_compo = "food-supplements-module--detailed_compo--iBPyZ";
export var detailed_compo_details = "food-supplements-module--detailed_compo_details--lcdo0";
export var detailed_compo_img = "food-supplements-module--detailed_compo_img--J529J";
export var detailed_compo_title = "food-supplements-module--detailed_compo_title--ARNxb";
export var doubt = "food-supplements-module--doubt--HgovI";
export var essentiel_title = "food-supplements-module--essentiel_title--V1ch8";
export var flex = "food-supplements-module--flex--btUfx";
export var flex__benefits = "food-supplements-module--flex__benefits--zCFoC";
export var illustration_wrapper = "food-supplements-module--illustration_wrapper--g5Spz";
export var image_wrapper = "food-supplements-module--image_wrapper--4LCIO";
export var image_wrapper__essentiel = "food-supplements-module--image_wrapper__essentiel--dPspH";
export var image_wrapper__options = "food-supplements-module--image_wrapper__options--gjWGG";
export var image_wrapper_author = "food-supplements-module--image_wrapper_author--S1AVc";
export var image_wrapper_author__options = "food-supplements-module--image_wrapper_author__options--YTIGy";
export var inserm_image = "food-supplements-module--inserm_image--3bhZI";
export var more = "food-supplements-module--more--v66nF";
export var price = "food-supplements-module--price--y9DgW";
export var price_wrapper = "food-supplements-module--price_wrapper--iEm3d";
export var product_contents__essentiel = "food-supplements-module--product_contents__essentiel--0aHzU";
export var product_contents__options = "food-supplements-module--product_contents__options--m+vvX";
export var product_rea = "food-supplements-module--product_rea--qr8KL";
export var product_title = "food-supplements-module--product_title--HJxHp";
export var rea = "food-supplements-module--rea--6xdUu";
export var rea_container = "food-supplements-module--rea_container--hPEf+";
export var section = "food-supplements-module--section--gaDhX";
export var section__benefits = "food-supplements-module--section__benefits--AyZhk";
export var section__fertility_age = "food-supplements-module--section__fertility_age--hVBBM";
export var section__options = "food-supplements-module--section__options--0zt7w";
export var show_more = "food-supplements-module--show_more--S8jnJ";
export var show_more_button = "food-supplements-module--show_more_button--OmWJI";
export var spacer = "food-supplements-module--spacer--iW7R1";
export var xp = "food-supplements-module--xp--+i+5M";
export var xp__options = "food-supplements-module--xp__options--EDR8s";
export var xp_author = "food-supplements-module--xp_author--+9+lA";
export var xp_image_wrapper = "food-supplements-module--xp_image_wrapper--PfOR4";
export var xp_role = "food-supplements-module--xp_role--gq6WD";