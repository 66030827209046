// extracted by mini-css-extract-plugin
export var bannerDesc = "innovation-module--bannerDesc--yJfNA";
export var bannerDesc_icon = "innovation-module--bannerDesc_icon--zwemV";
export var bannerDesc_title = "innovation-module--bannerDesc_title--yOXu1";
export var benefits = "innovation-module--benefits--OMpBU";
export var details = "innovation-module--details--cARJU";
export var details__2 = "innovation-module--details__2--UVo6u";
export var details_flex = "innovation-module--details_flex--zftyr";
export var details_item = "innovation-module--details_item--LCRy3";
export var flex = "innovation-module--flex--W5P1y";
export var header = "innovation-module--header--2o96-";
export var header_content = "innovation-module--header_content--UAi9o";
export var header_title = "innovation-module--header_title--i0IgP";
export var innovation = "innovation-module--innovation--R-Qdt";
export var innovation_content = "innovation-module--innovation_content--4enp0";
export var innovation_item = "innovation-module--innovation_item--p6HYj";
export var innovation_item_content = "innovation-module--innovation_item_content--Xbbxb";
export var innovation_items = "innovation-module--innovation_items--0DanH";
export var item = "innovation-module--item--awOX2";
export var item_icon = "innovation-module--item_icon--6N6Yf";
export var item_icon_img = "innovation-module--item_icon_img--S4Xpd";
export var item_index = "innovation-module--item_index--VSr1X";
export var item_title = "innovation-module--item_title--0DBIk";
export var meal_planner = "innovation-module--meal_planner--xOr5K";
export var meal_planner_image_1 = "innovation-module--meal_planner_image_1--SkX50";
export var meal_planner_image_2 = "innovation-module--meal_planner_image_2--P213i";
export var meal_planner_images = "innovation-module--meal_planner_images--FsDh9";
export var option_icon = "innovation-module--option_icon--4trFR";
export var rea_banner = "innovation-module--rea_banner--GqLRs";
export var rea_banner_content = "innovation-module--rea_banner_content--LpI3t";
export var rea_banner_icon = "innovation-module--rea_banner_icon--JIAo-";
export var rea_complements = "innovation-module--rea_complements--hFDaO";
export var rea_complements_component = "innovation-module--rea_complements_component--ZrI+J";
export var rea_complements_component__Img = "innovation-module--rea_complements_component__Img--IlQRI";
export var rea_complements_component_title = "innovation-module--rea_complements_component_title--Of3tE";
export var section = "innovation-module--section--mpWD5";
export var section_content = "innovation-module--section_content--sw5DH";
export var section_title = "innovation-module--section_title--zAxBq";
export var testimonial = "innovation-module--testimonial--B7Wqx";
export var testimonial_avatar = "innovation-module--testimonial_avatar--rcpbf";
export var testimonial_quote = "innovation-module--testimonial_quote--wOnN9";
export var testimonial_text = "innovation-module--testimonial_text--EZ5Re";
export var testimonial_title = "innovation-module--testimonial_title--SfZb3";