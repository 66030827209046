import React from 'react';
import * as styles from './navIcon-item.module.scss';

const NavIconItem = ({ item, index, isActive, isPreviousActive, onClick }) => (
  <a
    className={`
    ${styles.iconContainer} 
    ${styles[`iconContainer${index + 1}`]}
    ${isActive ? styles.active : ''} 
    ${isPreviousActive ? styles.previousActive : ''}`}
    href={item.href}
    onClick={(e) => {
      e.preventDefault();
      onClick(item.href);
    }}
  >
    <img src={item.image.publicURL} alt={item.alt} className={`${styles[`icon${index + 1}`]}`} />
  </a>
);

export default NavIconItem;
