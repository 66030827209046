// extracted by mini-css-extract-plugin
export var BlackBtn = "form-module--BlackBtn--lWmGt";
export var BtnDisable = "form-module--BtnDisable--pTJvL";
export var ButtonsContainer = "form-module--ButtonsContainer--cXBQH";
export var Checkbox = "form-module--Checkbox--2qJYe";
export var Consent = "form-module--Consent--sHYoc";
export var Error = "form-module--Error--Vl33e";
export var ErrorLeft = "form-module--ErrorLeft--rAFHW";
export var Input = "form-module--Input--2TQBd";
export var InputWhite = "form-module--InputWhite--oG6Jq";
export var InputWrapper = "form-module--InputWrapper--D42VB";
export var InputWrapperInput = "form-module--InputWrapperInput--GiPTJ";
export var InputWrapperLabel = "form-module--InputWrapperLabel--r2wtO";
export var Label = "form-module--Label--mrIPc";
export var Open = "form-module--Open--liAJC";
export var Option = "form-module--Option--cqCmL";
export var Picked = "form-module--Picked--znh6k";
export var Radio = "form-module--Radio--hBQ6e";
export var Select = "form-module--Select--a6IdY";
export var Select__Options = "form-module--Select__Options--rHVyf";
export var Select__Wrapper = "form-module--Select__Wrapper--+hFvK";
export var image_diag = "form-module--image_diag--h4q2v";