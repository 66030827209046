import React, { useState, useEffect } from 'react';

const ParcelShopWidget = ({ onPointSelected, onClose, data, onField }) => {
  const [token, setToken] = useState(null);
  const [ISO, setISO] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await fetch('https://ws.colissimo.fr/widget-colissimo/rest/authenticate.rest', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            login: process.env.GATSBY_COLISSIMO_LOGIN,
            password: process.env.GATSBY_COLISSIMO_PASSWORD,
          }),
        });

        const data = await response.json();
        if (response.ok) {
          setToken(data.token);
        } else {
          console.error('Error retrieving token', data);
        }
      } catch (error) {
        console.error('Error during query', error);
      }
    };

    fetchToken();
  }, [data]);

  useEffect(() => {
    switch (data.country) {
      case 'Belgique':
        setISO('BE');
        break;
      case 'Luxembourg':
        setISO('LU');
        break;
      case 'France':
        setISO('FR');
        break;
      default:
        break;
    }
  }, [data]);

  useEffect(() => {
    const loadScripts = async () => {
      if (typeof window.scrollTo !== 'function') {
        // Provide a no-op fallback for window.scrollTo
        window.scrollTo = () => {};
      }

      const jqueryScript = document.createElement('script');
      jqueryScript.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.js';
      jqueryScript.type = 'text/javascript';
      document.head.appendChild(jqueryScript);

      const colissimoScript = document.createElement('script');
      colissimoScript.src = 'https://ws.colissimo.fr/widget-colissimo/js/jquery.plugin.colissimo.min.js';
      colissimoScript.type = 'text/javascript';
      document.head.appendChild(colissimoScript);

      const mapboxScript = document.createElement('script');
      mapboxScript.src = 'https://api.mapbox.com/mapbox-gl-js/v2.6.1/mapbox-gl.js';
      mapboxScript.type = 'text/javascript';
      document.head.appendChild(mapboxScript);

      const mapboxLink = document.createElement('link');
      mapboxLink.href = 'https://api.mapbox.com/mapbox-gl-js/v2.6.1/mapbox-gl.css';
      mapboxLink.rel = 'stylesheet';
      document.head.appendChild(mapboxLink);

      jqueryScript.onload = () => {
        if (typeof window.$ === 'undefined') {
          console.error('jQuery did not load correctly.');
          return;
        }

        colissimoScript.onload = () => {
          const url_serveur = 'https://ws.colissimo.fr';

          if (token && document.getElementById('monIdDeWidgetColissimo')) {
            // Callback function for Colissimo widget
            window.maMethodeDeCallBack = (point) => {
              onPointSelected(point);
              setTimeout(onClose, 500);
            };

            window.$('#monIdDeWidgetColissimo').frameColissimoOpen({
              URLColissimo: url_serveur,
              ceCountryList: ['FR', 'LU', 'BE'],
              callBackFrame: 'maMethodeDeCallBack',
              ceCountry: ISO,
              ceAddress: data.adresse,
              ceZipCode: data.code,
              ceTown: data.city,
              token: token,
            });

            if (document.getElementsByClassName('widget_colissimo_close')) {
              document.getElementsByClassName('widget_colissimo_close')[0].addEventListener('click', () => {
                onClose();
                onField('relay_point', false);
              });
            }
          }
        };
      };
    };

    if (typeof window !== 'undefined') {
      loadScripts();
    }
  }, [token, onPointSelected, onClose, data]);

  return (
    <div>
      <div id="monIdDeWidgetColissimo" style={{ width: '60%', height: '500px', marginBottom: '100px' }}></div>
    </div>
  );
};

export default ParcelShopWidget;
