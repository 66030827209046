// extracted by mini-css-extract-plugin
export var arrow_left = "diets-module--arrow_left--u6kAZ";
export var arrow_right = "diets-module--arrow_right--KotZa";
export var button = "diets-module--button--mUEoS";
export var carousel = "diets-module--carousel--ufkCh";
export var custom_button_group = "diets-module--custom_button_group--cQi5n";
export var diet = "diets-module--diet--I68ew";
export var diet_description = "diets-module--diet_description--MALzy";
export var diet_name = "diets-module--diet_name--ITF5+";
export var diet_picture = "diets-module--diet_picture---UR41";
export var diet_tags = "diets-module--diet_tags--C+Wmn";
export var diet_wrapper = "diets-module--diet_wrapper--2rxNx";
export var diets = "diets-module--diets--p8gVS";
export var hide = "diets-module--hide--lHZot";
export var section = "diets-module--section--NvqLP";
export var star_bottom = "diets-module--star_bottom--qVeMn";
export var tag = "diets-module--tag--UN9TQ";
export var tag_wrapper = "diets-module--tag_wrapper--kneln";
export var title = "diets-module--title--Dul-I";