import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { dataEdge } from '../../functions';
import * as style from './modules/tips.module.scss';
import SEO from '../seo';

function Tips({ data, page }) {
  const { allStaticTipsYaml } = data;
  const {
    node: {
      title_homepage,
      title_essential_program,
      seo,
      content_homepage,
      content_essential_program,
      imageDesktop,
      imageMobile,
    },
  } = dataEdge(allStaticTipsYaml);

  let content = '<h1>Error Content !</h1';
  let title = '<h1>Error Title !</h1>';
  if (page === 'essentialprogram') {
    content = content_essential_program;
    title = title_essential_program;
  }
  if (page === 'homepage') {
    content = content_homepage;
    title = title_homepage;
  }
  return (
    <>
      <SEO title={seo.title} description={seo.description} /> {/* I'm not sure it should be there ! */}
      <div className={`${style.container}`}>
        <div className={`${style.img}`}>
          <GatsbyImage
            className={`${style.desktop}`}
            image={imageDesktop.childImageSharp.gatsbyImageData}
            alt={title}
          />
        </div>
        <div className={`${style.text}`}>
          <h2>{title}</h2>
          <div className={`${style.img}`}>
            <GatsbyImage
              className={`${style.mobile}`}
              image={imageMobile.childImageSharp.gatsbyImageData}
              alt={title}
            />
          </div>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </>
  );
}

export default function (props) {
  return (
    <StaticQuery
      render={(data) => <Tips data={data} {...props} />}
      query={graphql`
        {
          allStaticTipsYaml {
            edges {
              node {
                title_homepage
                title_essential_program
                seo {
                  title
                  description
                }
                content_homepage
                content_essential_program
                imageDesktop {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH, formats: [AUTO])
                  }
                }
                imageMobile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH, formats: [AUTO])
                  }
                }
              }
            }
          }
        }
      `}
    />
  );
}
