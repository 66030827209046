// extracted by mini-css-extract-plugin
export var BlackBtn = "prescription-popup-module--BlackBtn--6d9bg";
export var BlackBtnEnable = "prescription-popup-module--BlackBtnEnable--yrAs8";
export var BtnDisable = "prescription-popup-module--BtnDisable--pA1fe";
export var Error = "prescription-popup-module--Error--abvIt";
export var Success = "prescription-popup-module--Success---Btqs";
export var close = "prescription-popup-module--close--GDlLi";
export var fieldContainer = "prescription-popup-module--fieldContainer--5iZgz";
export var formContainer = "prescription-popup-module--formContainer--GmeHV";
export var input = "prescription-popup-module--input--U7LNK";
export var inputError = "prescription-popup-module--inputError--udKPi";
export var popup_container = "prescription-popup-module--popup_container--Sul+j";
export var popup_content = "prescription-popup-module--popup_content--JO8Y9";
export var popup_img = "prescription-popup-module--popup_img--DjuaK";
export var popup_title = "prescription-popup-module--popup_title---4Ye3";