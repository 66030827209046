// extracted by mini-css-extract-plugin
export var BM_stars = "sopk-module--BM_stars--khRqN";
export var RDV_container = "sopk-module--RDV_container---ysw0";
export var RDV_text = "sopk-module--RDV_text--m2Ycx";
export var RS_header = "sopk-module--RS_header--dWuN0";
export var RS_img = "sopk-module--RS_img--Tqg8M";
export var RS_img_link = "sopk-module--RS_img_link--7lmc-";
export var RS_imgs = "sopk-module--RS_imgs--tHOJs";
export var abo = "sopk-module--abo--wYt1g";
export var alma_logo = "sopk-module--alma_logo--6W6IF";
export var app = "sopk-module--app--sQ7H+";
export var app_col1 = "sopk-module--app_col1--eQ9xi";
export var app_col2 = "sopk-module--app_col2--Pbjm1";
export var app_img1 = "sopk-module--app_img1--t0ARu";
export var app_img2 = "sopk-module--app_img2--g7F+z";
export var app_img3 = "sopk-module--app_img3--qH6fp";
export var app_text = "sopk-module--app_text--kWn5X";
export var app_title = "sopk-module--app_title--GC-tP";
export var article_container = "sopk-module--article_container--RiU7y";
export var article_header = "sopk-module--article_header--wkBRJ";
export var audrey = "sopk-module--audrey--I74j-";
export var bar = "sopk-module--bar--Ynqtl";
export var btn = "sopk-module--btn--sFC1B";
export var btn_complement = "sopk-module--btn_complement--AgmSX";
export var btn_cplmt = "sopk-module--btn_cplmt---yuPn";
export var btn_rdv = "sopk-module--btn_rdv--Pshju";
export var btn_video = "sopk-module--btn_video--vImW2";
export var buy = "sopk-module--buy--Jxdsc";
export var buy_img = "sopk-module--buy_img--ryEKU";
export var buy_inputs = "sopk-module--buy_inputs--Hr7NR";
export var buy_inputs_details = "sopk-module--buy_inputs_details--0MQw-";
export var buy_inputs_price_text = "sopk-module--buy_inputs_price_text--j9pqX";
export var buy_inputs_subtitle = "sopk-module--buy_inputs_subtitle--2lsey";
export var buy_text = "sopk-module--buy_text--8v-xO";
export var complements = "sopk-module--complements--7GWuX";
export var complements_col1 = "sopk-module--complements_col1--vMGHp";
export var complements_col2 = "sopk-module--complements_col2--E0NFb";
export var complements_img1 = "sopk-module--complements_img1--L5WwS";
export var complements_img2 = "sopk-module--complements_img2--+ojy1";
export var complements_img3 = "sopk-module--complements_img3--VX0v8";
export var complements_text = "sopk-module--complements_text--MvK+m";
export var complements_title = "sopk-module--complements_title--1cOWS";
export var container_avis = "sopk-module--container_avis--x+rYi";
export var container_img_pack = "sopk-module--container_img_pack--5dS43";
export var emeline = "sopk-module--emeline--wHlvK";
export var error_msg = "sopk-module--error_msg--yG3wm";
export var h1 = "sopk-module--h1--DRghQ";
export var handBandContainer = "sopk-module--handBandContainer--3Q+RQ";
export var img_stars_buy = "sopk-module--img_stars_buy--MGKQO";
export var intro = "sopk-module--intro--VWVGG";
export var intro_btn = "sopk-module--intro_btn--kxiUg";
export var julie = "sopk-module--julie--MZwLi";
export var layout = "sopk-module--layout--ufhL0";
export var list = "sopk-module--list--C8bTg";
export var list_buy = "sopk-module--list_buy--m3V3k";
export var logo = "sopk-module--logo--gZTyT";
export var logo_clarna_container = "sopk-module--logo_clarna_container--oeXbg";
export var logo_klarna = "sopk-module--logo_klarna--KbHNB";
export var logos = "sopk-module--logos--a13Ma";
export var mailys = "sopk-module--mailys--O1rlW";
export var mobile_img = "sopk-module--mobile_img--mHf9F";
export var pack_desktop = "sopk-module--pack_desktop--++tDG";
export var pack_img = "sopk-module--pack_img--GP65g";
export var pack_mobile = "sopk-module--pack_mobile---vTvE";
export var pack_price = "sopk-module--pack_price--OIWHh";
export var packimg1 = "sopk-module--packimg1--WVmL9";
export var packimg2 = "sopk-module--packimg2--ursJH";
export var packimg3 = "sopk-module--packimg3--Y6niv";
export var pay_multiple = "sopk-module--pay_multiple--aare6";
export var plus = "sopk-module--plus--4r0lE";
export var podcast_container = "sopk-module--podcast_container--ZAJfo";
export var podcast_header = "sopk-module--podcast_header--VM+SG";
export var pregnant_women = "sopk-module--pregnant_women--J5K6x";
export var radio_legende = "sopk-module--radio_legende--Agn-B";
export var scrollButton = "sopk-module--scrollButton--I21MX";
export var scrollButtonImage = "sopk-module--scrollButtonImage--0HamD";
export var scrollButtonLeft = "sopk-module--scrollButtonLeft--CqXg8";
export var scrollButtonRight = "sopk-module--scrollButtonRight--KSeVx";
export var selectedBackground = "sopk-module--selectedBackground--bTfzO";
export var subtitle = "sopk-module--subtitle--tIHz3";
export var suivi = "sopk-module--suivi--VTByR";
export var suivi_img = "sopk-module--suivi_img--GcLZR";
export var suivi_text = "sopk-module--suivi_text--Qtg6L";
export var talk_about_us_title = "sopk-module--talk_about_us_title--KOwbk";
export var textSolution = "sopk-module--textSolution--gxtDX";
export var text_intro = "sopk-module--text_intro--4H3yX";
export var trustpilot = "sopk-module--trustpilot--EtmBR";
export var trustpilot_logo = "sopk-module--trustpilot_logo--P8Xuj";
export var trustpilot_note = "sopk-module--trustpilot_note--LW4Rl";
export var video = "sopk-module--video--KG93W";
export var video_cover = "sopk-module--video_cover--5NIdf";
export var widget = "sopk-module--widget---qsdd";
export var wrapper = "sopk-module--wrapper--EySlh";