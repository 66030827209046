import dayjs from 'dayjs';
import 'dayjs/locale/fr'; // French locale
import relativeTime from 'dayjs/plugin/relativeTime';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useState, useEffect, useRef } from 'react';
import FertilityTestModal from '../components/fertility-test/modal';
// Layout
import Layout from '../components/layout';
// React Helmet SEO
import SEO from '../components/seo';
import PackEssentiel from '../components/static/pack-essentiel';
// Component: ProgramDescription
import ProgramDescription from '../components/static/program-description';
import ProgramOptions from '../components/static/program-options';
// Component: Tips
import Tips from '../components/static/tips';
// Component: Reassurance BeMum
import Reassurance from '../components/static/rea';
import Testimonials from '../components/static/testimonials';
import Link from '../components/utils/link';
import * as style from '../modules/index.module.scss';
import PrescriptionPopup from '../components/prescription-popup/prescription-popup';

dayjs.locale('fr'); // Use french
dayjs.extend(relativeTime); // Relative time

function IndexPage({ data }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { seo, title, content, image, arrow, button, rea, sticky, calendly, partners } = data.pageIndexYaml;
  const totalSlides = Math.ceil(partners.length / 4);
  const carouselRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % (totalSlides + 1));
  };

  useEffect(() => {
    const handleTransitionEnd = () => {
      if (currentIndex >= totalSlides) {
        carouselRef.current.style.transition = 'none';
        setCurrentIndex(0);
        setTimeout(() => {
          carouselRef.current.style.transition = 'transform 0.5s ease-in-out';
        }, 50);
      } else if (currentIndex < 0) {
        carouselRef.current.style.transition = 'none';
        setCurrentIndex(totalSlides - 1);
        setTimeout(() => {
          carouselRef.current.style.transition = 'transform 0.5s ease-in-out';
        }, 50);
      }
    };

    const carousel = carouselRef.current;
    carousel.addEventListener('transitionend', handleTransitionEnd);
    return () => carousel.removeEventListener('transitionend', handleTransitionEnd);
  }, [currentIndex, totalSlides]);

  const slides = [...partners.slice(-4), ...partners, ...partners.slice(0, 4)];

  const [modalOpen, setModalOpen] = useState(false);
  const [calendlyModalOpen, setCalendlyModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const closeCalendlyModal = () => {
    setCalendlyModalOpen(false);
  };

  return (
    <Layout withBanner>
      <SEO title={seo.title} description={seo.description} canonical={seo.canonical} />
      <PrescriptionPopup modalOpen={calendlyModalOpen} closeModal={closeCalendlyModal} />
      <FertilityTestModal modalOpen={modalOpen} closeModal={closeModal} />

      {/* Header */}
      <header className={`${style.header}`}>
        <div className={style.header_img}>
          <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={title} />
        </div>

        <div className={`${style.header_title_wrapper}`}>
          <div className={`t1 mb2`}>
            <h1>{title}</h1>
          </div>
          <div className={`Relative ${style.header_content}`} dangerouslySetInnerHTML={{ __html: content }} />
          <Link className={`Btn Btn--Black`} to={button.link}>
            {button.text}
          </Link>
          <button
            type="button"
            onClick={() => setModalOpen(true)}
            style={{
              display: 'block',
              marginTop: '1rem',
              cursor: 'pointer',
              fontFamily: 'Poppins',
              textDecoration: 'underline',
            }}
          >
            J'ai déjà fait le test
          </button>
        </div>

        <div className={`Center ${style.header_arrow}`}>
          <img src={arrow.publicURL} alt="arrow" height="90px" />
        </div>
      </header>

      <header className={`${style.header__mobile}`}>
        <div className={`${style.hero__mobile}`}>
          <div className={style.header_img}>
            <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={title} objectPosition={'75% 50%'} />
          </div>

          <div>
            <div className={`t1 ${style.header_title__mobile} mb2`}>{title}</div>
            <Link className={`Btn Btn--Black`} to={button.link}>
              {button.text}
            </Link>
            <button
              type="button"
              onClick={() => setModalOpen(true)}
              style={{
                display: 'block',
                marginTop: '1rem',
                cursor: 'pointer',
                fontFamily: 'Poppins',
                textDecoration: 'underline',
              }}
            >
              J'ai déjà fait le test
            </button>
          </div>
        </div>

        <div className="wrapper">
          <div className={`Relative ${style.header_content__mobile}`} dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </header>

      <ProgramDescription />
      <PackEssentiel />
      <ProgramOptions />

      <div className={`Center ${style.rea}`}>
        <h2 className={`${style.rea_title}`}>{rea.title}</h2>
        <p className={`${style.rea_text}`}>{rea.text}</p>
        <Link to={rea.button.link} className={`Btn Btn--Reg`}>
          {rea.button.text}
        </Link>
      </div>

      <Testimonials />

      {/* Carousel */}
      <div className={style.carousel}>
        <div className={style.carouselText}>Ils parlent de nous !</div>
        <div
          className={style.carouselInner}
          ref={carouselRef}
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {slides.map((partner, index) => (
            <div className={style.carouselItem} key={index}>
              <a href={partner.link} target="_blank">
                <GatsbyImage image={partner.icon.childImageSharp.gatsbyImageData} alt={partner.alt} />
              </a>
            </div>
          ))}
        </div>
      </div>

      <div className={style.tipsCustom}>
        <Tips page="homepage" />
      </div>

      {/* Reassurance */}
      <Reassurance />

      <a className={style.sticky} onClick={() => setCalendlyModalOpen(true)}>
        <span>J'ai une prescription médicale</span>
        <img src={sticky.publicURL} alt="prescription" height="30px" />
      </a>

      <a
        className={style.calendly}
        href="https://calendly.com/bemum/15min?month=2024-11"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>RDV Tél avec BeMum</span>
        <img src={calendly.publicURL} alt="calendly" height="70px" />
      </a>
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
  {
    pageIndexYaml {
      title
      seo {
        title
        description
        canonical
      }
      content
      image {
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      arrow {
        publicURL
      }
      button {
        text
        link
      }
      rea {
        title
        text
        button {
          text
          link
        }
      }
      sticky {
        publicURL
      }
      calendly {
        publicURL
      }
      partners {
        alt
        icon {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        link
      }
    }
  }
`;
