import React from 'react';
import { withUnpublishedPreview } from 'gatsby-source-prismic';
import PostTemplate from '../templates/post';

import Layout from '../components/layout';
import SEO from '../components/seo';

import * as style from '../modules/404.module.scss';

const NotFoundPage = (props) => (
  <Layout {...props}>
    <SEO title="404: Page non trouvée" canonical="https://www.bemum.co/404/" />
    <div id={style.NotFound}>
      <div className="wrapper">
        <h1 className="t1 mb3">Page non trouvée</h1>
        <p className="p0">Cette page n'existe pas</p>
      </div>
    </div>
  </Layout>
);

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    post: PostTemplate,
  },
});
