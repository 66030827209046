// extracted by mini-css-extract-plugin
export var banner = "nutritionnistes-module--banner--J2Tyt";
export var diet = "nutritionnistes-module--diet--NQBPu";
export var diet__profile = "nutritionnistes-module--diet__profile--6JFCI";
export var diet_description = "nutritionnistes-module--diet_description--yM0Bk";
export var diet_name = "nutritionnistes-module--diet_name--Admiy";
export var diet_picture = "nutritionnistes-module--diet_picture--YYVXq";
export var diet_tags = "nutritionnistes-module--diet_tags--BtgTH";
export var diet_wrapper = "nutritionnistes-module--diet_wrapper--k29XS";
export var diets = "nutritionnistes-module--diets--2Jtkk";
export var header = "nutritionnistes-module--header--zXTQH";
export var mutuelle = "nutritionnistes-module--mutuelle--p-7Pf";
export var mutuelle__component = "nutritionnistes-module--mutuelle__component--EHs9R";
export var star_bottom = "nutritionnistes-module--star_bottom--oxftV";
export var tag = "nutritionnistes-module--tag--WLJc8";
export var tag_wrapper = "nutritionnistes-module--tag_wrapper--ZQIDI";