// extracted by mini-css-extract-plugin
export var CheckCoupon = "checkout-module--CheckCoupon--lLKFa";
export var Fixed = "checkout-module--Fixed--n+sTw";
export var Focused = "checkout-module--Focused--bh5Cw";
export var InputError = "checkout-module--InputError--EAddm";
export var Secure = "checkout-module--Secure--L3dx2";
export var Submit = "checkout-module--Submit--Ruh2V";
export var SubmitPay = "checkout-module--SubmitPay--VBFn6";
export var autocomplete_dropdown_container = "checkout-module--autocomplete_dropdown_container--GsUon";
export var center = "checkout-module--center--J6Eb2";
export var inputSearch = "checkout-module--inputSearch--W-8v7";
export var item = "checkout-module--item--shzmx";
export var location_search_container = "checkout-module--location_search_container--k3awb";
export var location_search_input = "checkout-module--location_search_input--K+PZu";
export var suggestion_item = "checkout-module--suggestion_item--OsfB3";
export var suggestion_item_active = "checkout-module--suggestion_item_active--oYovC";
export var textCenter = "checkout-module--text-center--qPlxV";