// extracted by mini-css-extract-plugin
export var boldText = "guide-popup-module--boldText--owZYI";
export var btn = "guide-popup-module--btn--ZjsEQ";
export var closeBtn = "guide-popup-module--closeBtn--geaid";
export var dl_guide = "guide-popup-module--dl_guide--VzHWi";
export var email = "guide-popup-module--email--6nYLu";
export var error = "guide-popup-module--error--tfoeZ";
export var guide = "guide-popup-module--guide--HqaRt";
export var guide_email = "guide-popup-module--guide_email--OAjLD";
export var guide_form = "guide-popup-module--guide_form--s6svo";
export var guide_form_title = "guide-popup-module--guide_form_title--FxXEX";
export var guide_img_desktop = "guide-popup-module--guide_img_desktop--lYL+t";
export var guide_img_mobile = "guide-popup-module--guide_img_mobile--jhOmr";
export var guide_inputs = "guide-popup-module--guide_inputs--g9TZ-";
export var guide_name = "guide-popup-module--guide_name--63uSb";
export var italicText = "guide-popup-module--italicText--p6Sew";
export var name = "guide-popup-module--name--PcQos";
export var popupContent = "guide-popup-module--popupContent--avJxL";