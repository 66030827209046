// extracted by mini-css-extract-plugin
export var additionalInfo = "dietary-supplements-module--additionalInfo--fhSRC";
export var arrowIcon = "dietary-supplements-module--arrowIcon--DX70h";
export var arrowIconClose = "dietary-supplements-module--arrowIconClose--KEzuW";
export var arrowIconContainer = "dietary-supplements-module--arrowIconContainer--gA2PL";
export var arrowIconOpen = "dietary-supplements-module--arrowIconOpen--PyIIJ";
export var author_name = "dietary-supplements-module--author_name--A8FdD";
export var author_section = "dietary-supplements-module--author_section--XKEZO";
export var author_text = "dietary-supplements-module--author_text--E+qbl";
export var benefitsImage = "dietary-supplements-module--benefitsImage--VE1SL";
export var benefits_section = "dietary-supplements-module--benefits_section--VAtFl";
export var benefits_section_white = "dietary-supplements-module--benefits_section_white--3dmL2";
export var bounce = "dietary-supplements-module--bounce--Pf00P";
export var closing = "dietary-supplements-module--closing--Twt3H";
export var compositionDetails = "dietary-supplements-module--compositionDetails--9-mF1";
export var compositionImage = "dietary-supplements-module--compositionImage--oUL-o";
export var compositionTitle = "dietary-supplements-module--compositionTitle--zca-X";
export var contents_icon = "dietary-supplements-module--contents_icon--JFm8M";
export var contents_items = "dietary-supplements-module--contents_items--thEmY";
export var contents_single_item = "dietary-supplements-module--contents_single_item--HJ0uf";
export var dietaryCoachImage = "dietary-supplements-module--dietaryCoachImage--lcugU";
export var dietarySupplementsContainer = "dietary-supplements-module--dietarySupplementsContainer--LaebF";
export var dietarySupplementsContent = "dietary-supplements-module--dietarySupplementsContent--HzgH9";
export var dietarySupplementsExpert = "dietary-supplements-module--dietarySupplementsExpert--JmADJ";
export var dietarySupplementsImage = "dietary-supplements-module--dietarySupplementsImage--YlBFf";
export var dietarySupplementsSection = "dietary-supplements-module--dietarySupplementsSection--f21lK";
export var dietaryTestimonial = "dietary-supplements-module--dietaryTestimonial--BCt00";
export var ingredients = "dietary-supplements-module--ingredients---Hqob";
export var notification = "dietary-supplements-module--notification--TVq5g";
export var open = "dietary-supplements-module--open--0sz1i";
export var optionalSupplements = "dietary-supplements-module--optionalSupplements--mvxm7";
export var optionalSupplementsContent = "dietary-supplements-module--optionalSupplementsContent--LF-es";
export var optionalSupplementsImage = "dietary-supplements-module--optionalSupplementsImage--ipx70";
export var optionalSupplementsImage2 = "dietary-supplements-module--optionalSupplementsImage2--MEFmK";
export var optionalSupplementsItem = "dietary-supplements-module--optionalSupplementsItem--e3OhX";
export var secondItemBackground = "dietary-supplements-module--secondItemBackground--MT4Sv";
export var xp_image_wrapper = "dietary-supplements-module--xp_image_wrapper--7delq";