// extracted by mini-css-extract-plugin
export var banner = "meal-planning-module--banner--6zaKf";
export var banner_content = "meal-planning-module--banner_content--XU2jY";
export var banner_icon = "meal-planning-module--banner_icon--7rqi7";
export var contents_icon = "meal-planning-module--contents_icon--mqInT";
export var contents_items = "meal-planning-module--contents_items--T96PA";
export var contents_single_item = "meal-planning-module--contents_single_item--oTrpj";
export var diet = "meal-planning-module--diet--H2e65";
export var diet_name = "meal-planning-module--diet_name--XwDx6";
export var diet_picture = "meal-planning-module--diet_picture--MQcv+";
export var diet_wrapper = "meal-planning-module--diet_wrapper--FWLuK";
export var flex = "meal-planning-module--flex--utrGP";
export var flex__section_3 = "meal-planning-module--flex__section_3--SmEzG";
export var image_wrapper = "meal-planning-module--image_wrapper--WztVr";
export var image_wrapper__section_3 = "meal-planning-module--image_wrapper__section_3--jHkYH";
export var part_1 = "meal-planning-module--part_1--T2lYB";
export var part_1_description = "meal-planning-module--part_1_description--HOKPW";
export var part_1_image = "meal-planning-module--part_1_image--Fkixx";
export var part_2 = "meal-planning-module--part_2--3vBZQ";
export var part_2_description = "meal-planning-module--part_2_description--WIRct";
export var part_2_image = "meal-planning-module--part_2_image--lgQsn";
export var part_2_subtitle = "meal-planning-module--part_2_subtitle--v2Za8";
export var part_title = "meal-planning-module--part_title--WbBM7";
export var section = "meal-planning-module--section--M2RB4";
export var section_1_image = "meal-planning-module--section_1_image--mEAHH";
export var section_1_title = "meal-planning-module--section_1_title--Ui3QC";
export var section_3_contents = "meal-planning-module--section_3_contents--a9xsb";
export var section_3_description = "meal-planning-module--section_3_description--awWy+";
export var section_3_highlight = "meal-planning-module--section_3_highlight--wDMCc";
export var section_3_new = "meal-planning-module--section_3_new--KQZEo";
export var section_3_title = "meal-planning-module--section_3_title--ElD2b";
export var small = "meal-planning-module--small--qsZBP";
export var star = "meal-planning-module--star--rtLSQ";
export var star_bottom = "meal-planning-module--star_bottom--ZRi2y";