import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import FertilityTestModal from '../../components/fertility-test/modal';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Link from '../../components/utils/link';
import * as style from '../../modules/diagnostic-page.module.scss';
import Partners from '../../components/static/partners';
import Team from '../../components/static/team';

function IndexPage({ data }) {
  const {
    pageDiagnosticYaml: { title, seo, button, process, testimonial },
  } = data;
  const [modalOpen, setModalOpen] = React.useState(false);
  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <SEO title={seo.title} description={seo.description} canonical={seo.canonical} />

      <Layout>
        <FertilityTestModal modalOpen={modalOpen} closeModal={closeModal} />

        <header className={`BgPurpleLighter ${style.header}`}>
          <h1 className={`t1`}>{title}</h1>
        </header>

        <header className={`BgPurpleLighter ${style.header_mobile} Center`}>
          <div className={`t1 ${style.header_mobile_title}`}>{title}</div>

          <Link className={`Btn Btn--Black`} to={button.link}>
            {button.text}
          </Link>
        </header>

        <div className={style.features}>
          {process.map((item, key) => (
            <div key={key} className={`${style.feature}`}>
              <div className={`${style.feature_number}`}>0{key + 1}</div>
              <div className={style.feature_content_wrapper}>
                <img alt={item.alt} src={item.image.publicURL} className={`${style.feature_image}`} />

                <p className={`${style.feature_content}`}>{item.content}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="Center">
          <Link className={`Btn Btn--Black`} to={button.link}>
            {button.text}
          </Link>
        </div>
        <div className="Center">
          <button type="button" onClick={() => setModalOpen(true)} className={style.modal_trigger}>
            J'ai déjà fait le test
          </button>
        </div>

        <div className={style.testimonial_wrapper}>
          <Testimonial {...testimonial} />
        </div>

        {/** @todo ADD WHO WE ARE */}
        <Partners />
        <Team />

        {/** @todo ADD Olivier's words */}
      </Layout>
    </>
  );
}

/**
 * UI Component representing a person giving a testimonial
 *
 * @param {*} props
 * @param {string} props.name Name of the person
 * @param {import('gatsby-image').FixedObject} props.avatar Picture of the person
 * @param {string} props.text Contents of the testimonial
 * @param {import('gatsby-image').FixedObject} props.quote Quote image
 *
 */
function Testimonial({ name, avatar, text, quote }) {
  return (
    <div className={style.testimonial}>
      <GatsbyImage image={quote.childImageSharp.gatsbyImageData} alt="quote" className={`lg-hide`} />

      {/** PERSON AVATAR */}
      <div className={style.testimonial_avatar}>
        <GatsbyImage image={avatar.childImageSharp.gatsbyImageData} alt={name} />
      </div>

      <div className={style.testimonial_contents}>
        {/** PERSON TEXT */}
        <div className={`${style.testimonial_text}`}>
          <GatsbyImage
            image={quote.childImageSharp.gatsbyImageData}
            alt="quote"
            className={`xs-hide sm-hide md-hide ${style.testimonial_quote}`}
          />
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </div>

        {/** PERSON DETAILS */}
        <div className={`${style.testimonial_name} Medium`}>{name}</div>
      </div>

      <GatsbyImage image={quote.childImageSharp.gatsbyImageData} alt="quote" className={`lg-hide`} />
    </div>
  );
}

export default IndexPage;

export const query = graphql`
  {
    pageDiagnosticYaml {
      title
      seo {
        title
        description
        canonical
      }
      button {
        text
        link
      }
      process {
        content
        image {
          publicURL
        }
        alt
      }
      testimonial {
        name
        text
        avatar {
          childImageSharp {
            gatsbyImageData(width: 245, placeholder: NONE, layout: FIXED)
          }
        }
        quote {
          childImageSharp {
            gatsbyImageData(width: 54, placeholder: NONE, layout: FIXED)
          }
        }
      }
    }
  }
`;
