// extracted by mini-css-extract-plugin
export var active = "referral-module--active--GNKpF";
export var additionalInfo = "referral-module--additionalInfo--sfyF4";
export var avis = "referral-module--avis--SoJE6";
export var avisContent = "referral-module--avisContent--L8XuN";
export var avisCustom = "referral-module--avisCustom--jrahn";
export var carouselIndicators = "referral-module--carouselIndicators--eotgL";
export var emailInput = "referral-module--emailInput--t5qht";
export var error = "referral-module--error--RJSD+";
export var fullText = "referral-module--fullText--srAiu";
export var handBandContainer = "referral-module--handBandContainer--GHBkf";
export var headerContent = "referral-module--headerContent--jTwW2";
export var headerSection = "referral-module--headerSection--rjhfL";
export var highlight = "referral-module--highlight--FZ7w5";
export var mobileOnly = "referral-module--mobileOnly--2EOBl";
export var partialText = "referral-module--partialText--c056L";
export var primaryButton = "referral-module--primaryButton--6pL3X";
export var readMoreButton = "referral-module--readMoreButton--1++mk";
export var referralItem = "referral-module--referralItem--qGJPV";
export var referralList = "referral-module--referralList--15SGn";
export var referralSection = "referral-module--referralSection--hRZrg";
export var referralSectionTitle = "referral-module--referralSectionTitle--i5iO4";
export var referralSystem = "referral-module--referralSystem--ktl+5";
export var registerSection = "referral-module--registerSection--A-C6x";
export var slideDown = "referral-module--slideDown--Ch2w1";
export var stepBox = "referral-module--stepBox--v-88F";
export var stepsContainer = "referral-module--stepsContainer--k84lX";
export var stepsSection = "referral-module--stepsSection--jXlHI";
export var trustpilot = "referral-module--trustpilot--nqX40";
export var trustpilotIcon = "referral-module--trustpilotIcon--q-iZe";
export var whyJoinBox = "referral-module--whyJoinBox--sgK4b";
export var whyJoinContainer = "referral-module--whyJoinContainer--8iO9S";
export var whyJoinDescription = "referral-module--whyJoinDescription--V9sGr";
export var whyJoinHeader = "referral-module--whyJoinHeader--+CZG0";
export var whyJoinIcon = "referral-module--whyJoinIcon--9U1dF";
export var whyJoinNumber = "referral-module--whyJoinNumber--s3zra";
export var whyJoinSection = "referral-module--whyJoinSection--fAvKR";
export var whyJoinTitle = "referral-module--whyJoinTitle--sUueM";