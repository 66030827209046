// extracted by mini-css-extract-plugin
export var arrow_left = "avis-module--arrow_left--fR49V";
export var arrow_right = "avis-module--arrow_right--uFSg8";
export var avisContainer = "avis-module--avisContainer--eIDSG";
export var avisContent = "avis-module--avisContent--hT-6J";
export var avisContentContainer = "avis-module--avisContentContainer--dV0qw";
export var avisDescription = "avis-module--avisDescription--ZgljO";
export var avisImage = "avis-module--avisImage--e3idv";
export var avisItem = "avis-module--avisItem--MTFaN";
export var avisName = "avis-module--avisName--OOoVq";
export var custom_button_group = "avis-module--custom_button_group--P276S";
export var hide = "avis-module--hide--Zqy-8";
export var quote = "avis-module--quote--NkFER";
export var tag = "avis-module--tag--tRpit";
export var trustpilot_stars = "avis-module--trustpilot_stars--2f8uO";