import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import * as styles from './modules/testimonials.module.scss';
import Link from '../utils/link';

/**
 * BeMum acquisition funnel > Offer page > Section #3
 * @returns {React.ReactElement}
 * @see funnel-offer.js
 */
function Testimonials({ coaching }) {
  const { allStaticNewTestimonialsYaml, quote } = useStaticQuery(graphql`
    query TestimonialsSectionQuery {
      allStaticNewTestimonialsYaml {
        edges {
          node {
            name
            role
            text
            avatar {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      quote: file(relativePath: { eq: "quote-white-removebg-light.png" }) {
        childImageSharp {
          gatsbyImageData(width: 54, placeholder: NONE, layout: FIXED)
        }
      }
    }
  `);

  return (
    <section className={`${styles.section}`}>
      <div className={`${styles.testimonials}`}>
        {/* Different title if displayed in coaching page */}
        {coaching ? (
          <h2 className={`${styles.title} h2`}>
            Les abonnées adorent les coachs <br className="sm-hide xs-hide" /> BeMum !
          </h2>
        ) : (
          <h2 className={`${styles.title} h2`}>
            Elles nous ont déjà fait confiance, <br className="sm-hide xs-hide" /> et elles s’en félicitent.
          </h2>
        )}

        <div className={`${styles.caroussel} ${styles.caroussel_left} xs-hide sm-hide md-hide`}>
          {/* Must repeat testimonials because there are not enought to keep on displaying */}
          {allStaticNewTestimonialsYaml.edges.map(
            (testimonial, key) =>
              key < 3 && (
                <Testimonial
                  key={key}
                  name={testimonial.node.name}
                  role={testimonial.node.role}
                  text={testimonial.node.text}
                  avatar={testimonial.node.avatar.childImageSharp.gatsbyImageData}
                  quote={quote.childImageSharp.gatsbyImageData}
                />
              )
          )}
          {allStaticNewTestimonialsYaml.edges.map(
            (testimonial, key) =>
              key < 3 && (
                <Testimonial
                  key={key}
                  name={testimonial.node.name}
                  role={testimonial.node.role}
                  text={testimonial.node.text}
                  avatar={testimonial.node.avatar.childImageSharp.gatsbyImageData}
                  quote={quote.childImageSharp.gatsbyImageData}
                />
              )
          )}
          {allStaticNewTestimonialsYaml.edges.map(
            (testimonial, key) =>
              key < 3 && (
                <Testimonial
                  key={key}
                  name={testimonial.node.name}
                  role={testimonial.node.role}
                  text={testimonial.node.text}
                  avatar={testimonial.node.avatar.childImageSharp.gatsbyImageData}
                  quote={quote.childImageSharp.gatsbyImageData}
                />
              )
          )}
        </div>

        <div className={`${styles.caroussel} ${styles.caroussel_right} xs-hide sm-hide md-hide`}>
          {/* Must repeat testimonials because there are not enought to keep on displaying */}
          {allStaticNewTestimonialsYaml.edges.map(
            (testimonial, key) =>
              key > 2 && (
                <Testimonial
                  key={key}
                  name={testimonial.node.name}
                  role={testimonial.node.role}
                  text={testimonial.node.text}
                  avatar={testimonial.node.avatar.childImageSharp.gatsbyImageData}
                  quote={quote.childImageSharp.gatsbyImageData}
                />
              )
          )}
          {allStaticNewTestimonialsYaml.edges.map(
            (testimonial, key) =>
              key > 2 && (
                <Testimonial
                  key={key}
                  name={testimonial.node.name}
                  role={testimonial.node.role}
                  text={testimonial.node.text}
                  avatar={testimonial.node.avatar.childImageSharp.gatsbyImageData}
                  quote={quote.childImageSharp.gatsbyImageData}
                />
              )
          )}
          {allStaticNewTestimonialsYaml.edges.map(
            (testimonial, key) =>
              key > 2 && (
                <Testimonial
                  key={key}
                  name={testimonial.node.name}
                  role={testimonial.node.role}
                  text={testimonial.node.text}
                  avatar={testimonial.node.avatar.childImageSharp.gatsbyImageData}
                  quote={quote.childImageSharp.gatsbyImageData}
                />
              )
          )}
        </div>

        <div className={`${styles.caroussel} lg-hide`}>
          {allStaticNewTestimonialsYaml.edges.map(
            (testimonial, key) =>
              key === 0 && (
                <Testimonial
                  key={key}
                  name={testimonial.node.name}
                  role={testimonial.node.role}
                  text={testimonial.node.text}
                  avatar={testimonial.node.avatar.childImageSharp.gatsbyImageData}
                  quote={quote.childImageSharp.gatsbyImageData}
                />
              )
          )}
        </div>

        <div className={`${styles.caroussel} lg-hide`}>
          {allStaticNewTestimonialsYaml.edges.map(
            (testimonial, key) =>
              key === 3 && (
                <Testimonial
                  key={key}
                  name={testimonial.node.name}
                  role={testimonial.node.role}
                  text={testimonial.node.text}
                  avatar={testimonial.node.avatar.childImageSharp.gatsbyImageData}
                  quote={quote.childImageSharp.gatsbyImageData}
                />
              )
          )}
        </div>

        <div className="Center">
          <Link to={`/temoignages/`} className={`Btn Btn--Reg Center ${styles.button}`}>
            En savoir plus
          </Link>
        </div>
      </div>
    </section>
  );
}

/**
 * UI Component representing a person giving a testimonial
 *
 * @param {*} props
 * @param {string} props.name Name of the person
 * @param {import('gatsby-image').FluidObject} props.avatar Picture of the person
 * @param {string} props.text Contents of the testimonial
 * @param {import('gatsby-image').FixedObject} props.quote Quote image
 *
 */
function Testimonial({ name, avatar, role, text, quote }) {
  return (
    <div className={styles.testimonial}>
      {/** PERSON AVATAR */}
      <div className={styles.testimonial_avatar}>
        <GatsbyImage image={avatar} alt={name} />
        <GatsbyImage image={quote} alt="quote" className={`lg-hide ${styles.testimonial_quote_mobile}`} />
      </div>

      <div className={styles.testimonial_contents}>
        {/** PERSON TEXT */}
        <div className={`${styles.testimonial_text}`}>
          <GatsbyImage image={quote} alt="quote" className={`xs-hide sm-hide md-hide ${styles.testimonial_quote}`} />
          <span>{text}</span>
        </div>

        {/** PERSON DETAILS */}
        <div className={`${styles.testimonial_name} Regular`}>{name}</div>
        <div className={`Regular`}>{role}</div>
      </div>
    </div>
  );
}

export default Testimonials;
