// extracted by mini-css-extract-plugin
export var arrow = "program-description-module--arrow--yK8s5";
export var feature = "program-description-module--feature--TBZdI";
export var feature_content = "program-description-module--feature_content--6DNWj";
export var feature_content_wrapper = "program-description-module--feature_content_wrapper--KLWvL";
export var feature_image = "program-description-module--feature_image--D6m5R";
export var feature_number = "program-description-module--feature_number--+xM9y";
export var feature_title = "program-description-module--feature_title--v6p3T";
export var features = "program-description-module--features--KLm8Z";
export var section = "program-description-module--section--+r-S9";
export var section_subtitle = "program-description-module--section_subtitle--i4Og3";
export var title = "program-description-module--title--OtvW-";