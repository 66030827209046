import React, { useContext, useEffect, useState } from 'react';
import CartContext from '../../context/CartContext';
import * as styles from './coupon-form.module.scss';
import useLocalStorage from '../../hooks/useLocalStorage';

/**
 * Allow a user to test and apply a
 * coupon to their cart
 */
function CouponForm() {
  const { cart, setCart } = useContext(CartContext);
  const states = Object.freeze({ PRISTINE: 0, LOADING: 1, UNEXPECTED_ERROR: 2, COUPON_ERROR: 3, COUPON_SUCCESS: 4 });
  const [state, setState] = useState(states.PRISTINE);
  const [errorMessage, setErrorMessage] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [candidateCoupon, setCandidateCoupon] = useLocalStorage('candidateCoupon', null);

  /**
   * What to do when the coupon <input> value is altered
   * @param {Event} event
   */
  function handleInputChange(event) {
    setState(states.PRISTINE);
    setInputValue(event.target.value);
  }

  /**
   * What to do when the coupon form is submitted
   * @param {Event} event
   */
  function submitCandidateCoupon(event) {
    event.preventDefault();
    setCandidateCoupon(inputValue);
  }

  /**
   * Go back to pristine state
   */
  function reset() {
    setInputValue('');
    setErrorMessage('');
    setCandidateCoupon('');
    setCart('remove', 'coupon');
    setState(states.PRISTINE);
  }

  // Fired each time `cart` is updated
  useEffect(() => {
    if (!cart) {
      return () => {};
    }
    if (!cart.coupon) {
      setInputValue('');
      setCandidateCoupon('');
      setState(states.PRISTINE);
    }
    if (cart.coupon && cart.coupon.code) {
      return setCandidateCoupon(cart.coupon.code);
    }
  }, [cart]);

  // Fired each time `candidateCoupon` is updated
  useEffect(() => {
    if (!candidateCoupon) {
      return () => {};
    }
    verifyCouponValidity();

    /** Verify validity of `candidateCoupon` through the BeMum API */
    async function verifyCouponValidity() {
      setState(states.LOADING);
      const endpoint = `${process.env.GATSBY_API}/stripe/promotion-code/${candidateCoupon}`;
      try {
        const response = await fetch(endpoint);
        switch (response.status) {
          case 200:
            const coupon = await response.json();
            if (isCouponAppliesTo(coupon.coupon) && !isCouponAppliesToValid(coupon.coupon)) {
              setState(states.COUPON_ERROR);
              setErrorMessage(`Le code n'est pas valide pour ce produit`);
              break;
            }
            setState(states.COUPON_SUCCESS);
            setCart('add', 'coupon', coupon);
            setErrorMessage(null);
            break;
          case 404:
            setState(states.COUPON_ERROR);
            setErrorMessage(`Le code est erroné ou périmé`);
            break;
          default:
            setState(states.UNEXPECTED_ERROR);
        }
      } catch (err) {
        setCart('remove', 'coupon');
        setState(states.UNEXPECTED_ERROR);
        setErrorMessage(`Une erreur réseau s'est produite.`);
      }
    }
  }, [candidateCoupon]);

  /** Verify if coupon applies to a specific product */
  function isCouponAppliesTo(coupon) {
    return coupon.applies_to && coupon.applies_to.products && coupon.applies_to.products.length > 0 ? true : false;
  }

  /** Verify if coupon applies to a specific product */
  function isCouponAppliesToValid(coupon) {
    const products = coupon.applies_to.products;
    const items = cart.contents.items.map((i) => i.stripe_product_id);
    return products.some((p) => items.includes(p));
  }

  const couponSuccess = state === states.COUPON_SUCCESS ? styles.couponSuccess : null;
  const couponError = state === states.COUPON_ERROR ? styles.couponError : null;

  if (states.COUPON_SUCCESS === state) {
    return (
      <div style={{ position: 'relative' }}>
        <p className={`${styles.feedbackMessage} p1`}>
          <span role="img" aria-label="tada">
            🎉
          </span>{' '}
          Le code {candidateCoupon} a été appliqué à votre commande.
          <button className={`${styles.resetButton} p1`} onClick={() => reset()}>
            Annuler?
          </button>
        </p>
      </div>
    );
  }

  return (
    <div style={{ position: 'relative' }}>
      <p className={`${styles.p1} p1`}>Vous avez un code promo ?</p>

      <form className={styles.form} onSubmit={submitCandidateCoupon}>
        <input
          className={`${couponSuccess} ${couponError}`}
          disabled={state === states.LOADING}
          type="text"
          placeholder="Code promo"
          onChange={handleInputChange}
          value={inputValue}
        />
        <button type="submit" disabled={state === states.LOADING} onClick={submitCandidateCoupon}>
          Appliquer
        </button>
      </form>
      {[states.UNEXPECTED_ERROR, states.COUPON_ERROR].includes(state) && (
        <p className={`${styles.feedbackMessage} ${styles.errorMessage} p1`}>
          {errorMessage || "Une erreur s'est produite."}
        </p>
      )}
    </div>
  );
}

export default CouponForm;
