// extracted by mini-css-extract-plugin
export var BtnDisabled = "programme-2024-module--BtnDisabled--xBsJ-";
export var appInfo = "programme-2024-module--appInfo--kS4Fx";
export var appInfoImage = "programme-2024-module--appInfoImage--Ls6gu";
export var appInfoSubscription = "programme-2024-module--appInfoSubscription--idiV4";
export var arrowIcon = "programme-2024-module--arrowIcon--ykbsp";
export var arrowIconOpen = "programme-2024-module--arrowIconOpen--JfBUv";
export var avis = "programme-2024-module--avis--N8veT";
export var avisContent = "programme-2024-module--avisContent--u3ilW";
export var baseStyles = "programme-2024-module--baseStyles--bE+8m";
export var bounce = "programme-2024-module--bounce--razCw";
export var buttonStyle = "programme-2024-module--buttonStyle--tYylV";
export var choicesContainer = "programme-2024-module--choicesContainer--Kk2Yx";
export var coachingContainer = "programme-2024-module--coachingContainer--GSBLi";
export var coachingContent = "programme-2024-module--coachingContent--cuXUj";
export var coachingContentFirstBlock = "programme-2024-module--coachingContentFirstBlock--Ib0Ax";
export var coachingContentFirstList = "programme-2024-module--coachingContentFirstList--sPv9p";
export var coachingContentSecondList = "programme-2024-module--coachingContentSecondList--nOZal";
export var coachingImage = "programme-2024-module--coachingImage--YqZjn";
export var contents_icon = "programme-2024-module--contents_icon--xo65k";
export var contents_items = "programme-2024-module--contents_items--lo18z";
export var contents_single_item = "programme-2024-module--contents_single_item---Ig99";
export var desktopButtonContainer = "programme-2024-module--desktopButtonContainer--nbvsJ";
export var dietarySupplementsContainer = "programme-2024-module--dietarySupplementsContainer--ztyFo";
export var extraCoachingContainer = "programme-2024-module--extraCoachingContainer--glUWF";
export var extraCoachingDescription = "programme-2024-module--extraCoachingDescription--O8kxT";
export var extraCoachingImage = "programme-2024-module--extraCoachingImage--Ta5Pm";
export var extraCoachingPrice = "programme-2024-module--extraCoachingPrice--X5dX2";
export var extraCoachingSubTitle = "programme-2024-module--extraCoachingSubTitle--yMgUf";
export var extraCoachingTitle = "programme-2024-module--extraCoachingTitle--vHcUi";
export var fixedNavIconContainer = "programme-2024-module--fixedNavIconContainer--BV0Q2";
export var food_program_img = "programme-2024-module--food_program_img--A2dAW";
export var highlightedText = "programme-2024-module--highlightedText--zR+uS";
export var mobileButtonContainer = "programme-2024-module--mobileButtonContainer--VDEtT";
export var navIconContainer = "programme-2024-module--navIconContainer--qLUMk";
export var programmeContainer = "programme-2024-module--programmeContainer--XFsLU";
export var programmeContainerTitle = "programme-2024-module--programmeContainerTitle--i9Ytr";
export var rdv = "programme-2024-module--rdv--rklda";
export var recItems = "programme-2024-module--recItems--vXL4i";
export var recSection = "programme-2024-module--recSection--Bae9z";
export var recipeContainer = "programme-2024-module--recipeContainer--NjyP9";
export var recipeContent = "programme-2024-module--recipeContent--z0DkF";
export var recipeImage = "programme-2024-module--recipeImage--rAxSa";
export var recipeList = "programme-2024-module--recipeList--Ac4xe";
export var refund_infoContainer = "programme-2024-module--refund_infoContainer--CM3NK";
export var refund_infoDescription = "programme-2024-module--refund_infoDescription--WVdxj";
export var refund_infoTitle = "programme-2024-module--refund_infoTitle--dFJoR";
export var smoothScroll = "programme-2024-module--smooth-scroll--7f75W";
export var trustpilot = "programme-2024-module--trustpilot--LV446";
export var trustpilotIcon = "programme-2024-module--trustpilotIcon--2qo+a";
export var validateButton = "programme-2024-module--validateButton--QJzYK";
export var video = "programme-2024-module--video--zTJBp";
export var videoContainer = "programme-2024-module--videoContainer--fY9hy";