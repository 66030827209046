/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import { CartProvider } from './src/context/CartContext';
import { AudioProvider } from './src/context/AudioContext';
import { PreviewStoreProvider } from 'gatsby-source-prismic';
import 'gatsby-plugin-prismic-previews/dist/styles.css';

if (!Array.prototype.filter) {
  Array.prototype.filter = function (func, thisArg) {
    if (!(typeof func === 'function' && this)) throw new TypeError();

    var len = this.length >>> 0,
      res = new Array(len), // preallocate array
      t = this,
      c = 0,
      i = -1;

    var kValue;
    if (thisArg === undefined) {
      while (++i !== len) {
        // checks to see if the key was set
        if (i in this) {
          kValue = t[i]; // in case t is changed in callback
          if (func(t[i], i, t)) {
            res[c++] = kValue;
          }
        }
      }
    } else {
      while (++i !== len) {
        // checks to see if the key was set
        if (i in this) {
          kValue = t[i];
          if (func.call(thisArg, t[i], i, t)) {
            res[c++] = kValue;
          }
        }
      }
    }

    res.length = c; // shrink down array to proper size
    return res;
  };
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  try {
    // @see https://github.com/fablife/bemum/issues/834
    window.pintrk('track', 'pagevisit', {
      path: location.pathname,
    });
    window.wisepops('pageview');
  } catch (e) {}
};



export const wrapRootElement = ({ element }) => (
  <PreviewStoreProvider>
    <CartProvider>
      <AudioProvider>{element}</AudioProvider>
    </CartProvider>
  </PreviewStoreProvider>
);
