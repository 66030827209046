import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import * as styles from './modules/diets.module.scss';
import Link from '../utils/link';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function Diets() {
  const {
    pageNutritionnistesYaml: { diets },
    star,
    arrow,
  } = useStaticQuery(graphql`
    query DietsSectionQuery {
      pageNutritionnistesYaml {
        diets {
          name
          tags {
            value
          }
          description
          picture {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
      star: file(relativePath: { eq: "icons/star.svg" }) {
        publicURL
      }
      arrow: file(relativePath: { eq: "arrows/half-arrow.svg" }) {
        publicURL
      }
    }
  `);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1225 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1224, min: 1024 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 1024, min: 0 },
      items: 1,
    },
  };

  return (
    <section className={`${styles.section}`}>
      <div className={styles.diets}>
        <h2 className={styles.title}>
          Découvrez les <br className="sm-hide xs-hide" /> coachs fertilité BeMum.
        </h2>

        <Carousel
          responsive={responsive}
          className={styles.carousel}
          arrows={false}
          renderButtonGroupOutside={true}
          customButtonGroup={<ButtonGroup arrow={arrow} />}
        >
          {diets.map((diet, key) => (
            <Diet
              key={key}
              name={diet.name}
              tags={diet.tags}
              description={diet.description}
              picture={diet.picture.childImageSharp.gatsbyImageData}
              star={star.publicURL}
            />
          ))}
        </Carousel>

        <div className="Center">
          <Link to={`/nos-nutritionnistes/`} className={`Btn Btn--Reg Center ${styles.button}`}>
            En savoir plus
          </Link>
        </div>
      </div>
    </section>
  );
}

const ButtonGroup = ({ arrow, next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow },
  } = rest;

  return (
    <div className={styles.custom_button_group}>
      <button className={currentSlide === 0 ? `${styles.hide}` : ''} onClick={() => previous()}>
        <img className={styles.arrow_left} height="30" src={arrow.publicURL} alt="left" />
      </button>
      <button className={currentSlide + slidesToShow === totalItems ? `${styles.hide}` : ''} onClick={() => next()}>
        <img className={styles.arrow_right} height="30" src={arrow.publicURL} alt="right" />
      </button>
    </div>
  );
};

/**
 * UI Component representing a diet description
 *
 * @param {*} props
 * @param {string} props.name Name of the diet
 * @param {import('gatsby-image').FluidObject} props.picture Picture of the diet
 * @param {string} props.tags Tags for the diet
 * @param {string} props.description Description of the diet
 * @param {import('gatsby-image').publicURL} props.star Star image
 *
 */
function Diet({ name, picture, tags, description, star }) {
  return (
    <div className={styles.diet_wrapper}>
      <div className={styles.diet}>
        <img src={star} height="30" alt="star" />

        <div className={`mb3`}>
          <div className={styles.diet_picture}>
            <GatsbyImage image={picture} alt={name} />
          </div>

          <div className={styles.diet_name}>{name}</div>

          <div className={styles.diet_tags}>
            {tags && (
              <ul className={`${styles.tag_wrapper}`}>
                {tags.map((tag, i) => (
                  <li key={i} className={`mb1`}>
                    <p className={`Center Black Regular ${styles.tag}`}>{tag.value}</p>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className={styles.diet_description}>{description}</div>
        </div>
        <img src={star} height="30" className={styles.star_bottom} alt="star" />
      </div>
    </div>
  );
}

export default Diets;
