// extracted by mini-css-extract-plugin
export var button = "testimonials-module--button--4r-CI";
export var caroussel = "testimonials-module--caroussel--DamkR";
export var caroussel_left = "testimonials-module--caroussel_left--EF1w8";
export var caroussel_right = "testimonials-module--caroussel_right--PBobb";
export var scrollingLeft1 = "testimonials-module--scrolling-left1--Pr290";
export var scrollingRight = "testimonials-module--scrolling-right--PvNWk";
export var section = "testimonials-module--section--w3lI3";
export var testimonial = "testimonials-module--testimonial--ZjmkQ";
export var testimonial_avatar = "testimonials-module--testimonial_avatar--wD+qZ";
export var testimonial_contents = "testimonials-module--testimonial_contents--Z6pMU";
export var testimonial_name = "testimonials-module--testimonial_name--678YF";
export var testimonial_quote = "testimonials-module--testimonial_quote--B64Ga";
export var testimonial_quote_mobile = "testimonials-module--testimonial_quote_mobile--edLBr";
export var testimonial_text = "testimonials-module--testimonial_text--CDoNS";
export var testimonials = "testimonials-module--testimonials--eNnLa";
export var title = "testimonials-module--title--VQwh-";