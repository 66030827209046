import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import * as styles from './faq.module.scss';

/**
 * BeMum acquisition funnel > Offer page > Section #5
 * @returns {React.ReactElement}
 * @see resultats.js
 */
function Faq() {
  const { allStaticFaqYaml } = useStaticQuery(graphql`
    query FaqSectionQuery {
      allStaticFaqYaml {
        edges {
          node {
            question
            answer
            link
          }
        }
      }
    }
  `);

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div className={`Center t2`}>FAQ</div>

        <div className={styles.questions}>
          {allStaticFaqYaml.edges.map((edge, index) => (
            <div key={index} className={styles.questionWrapper}>
              <div className={`${styles.question} t3`}>{edge.node.question}</div>
              <div className={`${styles.answer} p0`}>{edge.node.answer}</div>

              <div className={styles.buttonWrapper}>
                <a
                  href={edge.node.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${styles.showMoreLg} p0`}
                >
                  LIRE PLUS
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Faq;
