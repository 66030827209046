import { Link } from 'gatsby';
import React, { useState } from 'react';
import * as styles from '../modules/flyer-prescription.module.scss';
import axios from 'axios';

const CtaButton = ({ text, link, icon, ext, phoneNumber, setShowPopup, setValidText }) => {
  const [inputNum, setInputNum] = useState('');
  const [showInputNumber, setShowInputNumber] = useState(false);

  const phoneRegex = new RegExp(/^((\+)33|0)[1-9](\d{8})$/);

  function handleChange(e) {
    const number = e.target.value;
    setInputNum(number);
  }

  function sendPhoneNumbertoSlack(prospectNumber) {
    const text = `*DEMANDE D'APPEL* \nUn prospect a demandé à avoir plus d'informations sur le programme Bemum ! \nVous pouvez le joindre au : *${prospectNumber}*`;

    axios.post(`${process.env.GATSBY_API}/patients/call-prospect`, { text: text }).then((response) => {
      console.log(response);
    });
  }
  const handlePhoneNumberSubmit = () => {
    const isValidPhone = phoneRegex.test(inputNum);
    if (isValidPhone) {
      setValidText('Envoyé !');
      sendPhoneNumbertoSlack(inputNum);
      setInputNum('');
    } else {
      setValidText('Numéro Invalide !');
    }
    setShowPopup(true);
  };

  return (
    <div className={`${styles.ctaButton}`}>
      <div className={`${styles.ctaIcon} ${styles[icon]}`} />
      {phoneNumber ? (
        <div className={`${styles.link}`}>
          <div onClick={() => setShowInputNumber(true)} className={`${styles.ctaText}`}>
            {!showInputNumber ? (
              <span> {text}</span>
            ) : (
              <div className={styles.inputContainer}>
                <input
                  className="Btn"
                  onChange={handleChange}
                  type="tel"
                  id="phone"
                  name="phone"
                  value={inputNum}
                  placeholder={'saisissez votre N° de téléphone'}
                />
                <span onClick={handlePhoneNumberSubmit} className={styles.arrow}></span>
              </div>
            )}
          </div>
        </div>
      ) : ext ? (
        <a className={`${styles.link}`} href={link}>
          <div className={`${styles.ctaText}`}>
            <span> {text}</span>
          </div>
        </a>
      ) : (
        <Link className={`${styles.link}`} to={link}>
          <div className={`${styles.ctaText}`}>
            <span> {text}</span>
          </div>
        </Link>
      )}
    </div>
  );
};

function FlyerPrescription() {
  const [showPopup, setShowPopup] = useState(false);
  const [validText, setValidText] = useState('Envoyé !');

  return (
    <>
      {showPopup && (
        <div onClick={() => setShowPopup(false)} className={styles.popup}>
          <div className={styles.content}>
            <span>{validText}</span>
            <button className={styles.close} onClick={() => setShowPopup(false)}>
              OK
            </button>
          </div>
        </div>
      )}
      <div className={styles.content}>
        <div className={`${styles.logo}`} />
        <CtaButton text={'Découvrir le programme Bemum'} arrow={true} link={'/'} icon={'discover'} />
        <CtaButton text={'Contactez-nous au 01 86 90 55 75'} link={'tel:0186905575'} ext={true} icon={'contact_us'} />
        <CtaButton
          text={'Prendre rendez-vous'}
          arrow={true}
          text2={'Renseignez votre numéro de téléphone'}
          link={'https://calendly.com/bemum/15min'}
          icon={'rdv'}
          ext={true}
        />
        <CtaButton
          text={'Demander à être rappelée'}
          setValidText={setValidText}
          setShowPopup={setShowPopup}
          phoneNumber={true}
          icon={'phone'}
        />
        <p className={styles.flyerFooter}>Bemum.co</p>
      </div>
    </>
  );
}
export default FlyerPrescription;
