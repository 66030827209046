import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as style from './meal-planning.module.scss';
import Tips from '../static/tips';

const MealPlanning = ({ data }) => {
  const {
    pageMealPlanningYaml: {
      icon,
      section_1,
      banner,
      section_2: { title, part_1, part_2 },
      section_3,
    },
  } = data;

  return (
    <>
      <div>
        <section className={`${style.section} ${style.flex}`}>
          <div>
            <h2 className={style.section_1_title}>{section_1.title}</h2>
            <h3 className="mb4">{section_1.sub_title}</h3>
            <h3 className="mb4">{section_1.detail}</h3>
            <div className="p0 BlackLighter">
              {section_1.items && (
                <ul>
                  {section_1.items.map((item, key) => (
                    <li key={key} className={style.contents_items}>
                      <div className={style.contents_icon} dangerouslySetInnerHTML={{ __html: icon }} />
                      <div className={style.contents_single_item}>{item}</div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <p className={`${style.small} xs-hide sm-hide md-hide`}>{section_1.small}</p>
          </div>
          <div className={`${style.image_wrapper} ${style.section_1_image}`}>
            <GatsbyImage image={section_1.photo.image.childImageSharp.gatsbyImageData} alt={section_1.photo.alt} />
          </div>
          <p className={`${style.small} lg-hide`}>{section_1.small}</p>
        </section>

        <section className={`BgRedLighter ${style.banner} `}>
          <div className={style.banner_icon} dangerouslySetInnerHTML={{ __html: icon }} />
          <div className={style.banner_content}>{banner}</div>
          <div className={style.banner_icon} dangerouslySetInnerHTML={{ __html: icon }} />
        </section>

        <section className={style.section}>
          <h2 className="t1">{title}</h2>

          <div className={`p0 BlackLighter ${style.part_1}`}>
            <div className={style.part_title}>{part_1.title}</div>
            <p className={`${style.part_1_description}`}>{part_1.description}</p>

            <div className={style.flex}>
              <div className={`${style.image_wrapper} ${style.part_1_image}`}>
                <GatsbyImage image={part_1.photo.image.childImageSharp.gatsbyImageData} alt={part_1.photo.alt} />
                <GatsbyImage image={part_1.photo_2.image.childImageSharp.gatsbyImageData} alt={part_1.photo_2.alt} />
              </div>
              <div>
                <p className={`Bold mb2`}>{part_1.rea_1.text}</p>
                <div>
                  {part_1.rea_1.items && (
                    <ul>
                      {part_1.rea_1.items.map((item, key) => (
                        <li key={key} className={style.contents_items}>
                          <div className={style.contents_icon} dangerouslySetInnerHTML={{ __html: icon }} />
                          <div className={style.contents_single_item}>{item}</div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <p className={`Bold mb2`}>{part_1.rea_2.text}</p>
                <div>
                  {part_1.rea_2.items && (
                    <ul>
                      {part_1.rea_2.items.map((item, key) => (
                        <li key={key} className={style.contents_items}>
                          <div className={style.contents_icon} dangerouslySetInnerHTML={{ __html: icon }} />
                          <div className={style.contents_single_item}>{item}</div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={style.part_2}>
            <div className={style.part_title}>{part_2.title}</div>
            <div className={style.flex}>
              <div>
                <h3 className={style.part_2_subtitle}>{part_2.sub_title}</h3>
                <p className={style.part_2_description}>{part_2.description}</p>
                <div className={`${style.image_wrapper} ${style.part_2_image}`}>
                  <GatsbyImage image={part_2.photo.image.childImageSharp.gatsbyImageData} alt={part_2.photo.alt} />
                  <GatsbyImage image={part_2.photo_2.image.childImageSharp.gatsbyImageData} alt={part_2.photo_2.alt} />
                </div>
              </div>
              <div className={style.diet_wrapper}>
                <div className={style.diet}>
                  <div className={style.star} dangerouslySetInnerHTML={{ __html: icon }} />

                  <div>
                    <div className={style.diet_picture}>
                      <GatsbyImage
                        image={part_2.coaching.photo.image.childImageSharp.gatsbyImageData}
                        alt={part_2.coaching.photo.alt}
                      />
                    </div>

                    <div className={style.diet_name}>{part_2.coaching.title}</div>

                    <div className={style.diet_description}>{part_2.coaching.detail}</div>
                  </div>
                  <div className={`${style.star} ${style.star_bottom}`} dangerouslySetInnerHTML={{ __html: icon }} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={`${style.section} BgPurpleLighter`}>
          <h2 className={style.section_3_title}>{section_3.title}</h2>
          <div className={`${style.flex} ${style.flex__section_3}`}>
            <div className={`${style.image_wrapper__section_3}`}>
              <GatsbyImage image={section_3.photo.image.childImageSharp.gatsbyImageData} alt={section_1.photo.alt} />
            </div>
            <div className={style.section_3_contents}>
              <h3 className={style.section_3_description}>{section_3.description}</h3>
              <div>
                {section_3.items && (
                  <ul>
                    {section_3.items.map((item, key) => (
                      <li key={key} className={`${style.contents_items} p0 BlackLighter`}>
                        <div className={style.contents_icon} dangerouslySetInnerHTML={{ __html: icon }} />
                        <div className={style.contents_single_item}>{item}</div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className={`p0 BlackLighter ${style.section_3_highlight}`}>
                <span className={`Red Bold ${style.section_3_new}`}>Nouveau </span> : {section_3.highlight}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Tips page="essentialprogram" />
    </>
  );
};

export default function (props) {
  return (
    <StaticQuery
      render={(data) => <MealPlanning data={data} {...props} />}
      query={graphql`
        {
          pageMealPlanningYaml {
            icon
            banner
            section_1 {
              title
              sub_title
              detail
              items
              small
              photo {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                  }
                }
              }
            }
            section_2 {
              title
              part_1 {
                title
                description
                photo {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                    }
                  }
                }
                photo_2 {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                    }
                  }
                }
                rea_1 {
                  text
                  items
                }
                rea_2 {
                  text
                  items
                }
              }
              part_2 {
                title
                sub_title
                description
                photo {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                    }
                  }
                }
                photo_2 {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                    }
                  }
                }
                coaching {
                  title
                  detail
                  photo {
                    alt
                    image {
                      childImageSharp {
                        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                      }
                    }
                  }
                }
              }
            }
            section_3 {
              title
              description
              items
              highlight
              photo {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      `}
    />
  );
}
