import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { dataEdge } from '../../functions';
import Link from '../utils/link';
import * as style from './modules/program-options.module.scss';

function PackEssentiel({ data }) {
  const { allStaticProgramOptionsYaml } = data;
  const {
    node: { content },
  } = dataEdge(allStaticProgramOptionsYaml);

  return (
    <div className={style.wrapper}>
      <h2 className={`${style.title} t2`}>{content.title}</h2>

      <div className={style.flex}>
        <section className={style.image_wrapper}>
          <div className={`${style.image} ${style.ellipsis} ${style.ellipsis_1}`} />
          <div className={`${style.ellipsis} ${style.ellipsis_2}`} />
          <div className={`${style.ellipsis} ${style.ellipsis_3}`} />
        </section>

        <section className={style.contents}>
          {content.items && (
            <ul>
              {content.items.map((item, key) => (
                <li key={key} className={style.contents_items}>
                  <div dangerouslySetInnerHTML={{ __html: item }} />
                </li>
              ))}
            </ul>
          )}

          <Link to={content.button.link} className={`Btn Btn--Black ${style.contents_button}`}>
            {content.button.text}
          </Link>
        </section>
      </div>
    </div>
  );
}

export default function (props) {
  return (
    <StaticQuery
      render={(data) => <PackEssentiel data={data} {...props} />}
      query={graphql`
        {
          allStaticProgramOptionsYaml {
            edges {
              node {
                content {
                  title
                  items
                  button {
                    text
                    link
                  }
                }
              }
            }
          }
        }
      `}
    />
  );
}
