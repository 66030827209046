// extracted by mini-css-extract-plugin
export var contents = "program-options-module--contents--iQIdA";
export var contents_button = "program-options-module--contents_button--m9FDv";
export var contents_description = "program-options-module--contents_description--78DFw";
export var contents_items = "program-options-module--contents_items--qehFl";
export var contents_rea = "program-options-module--contents_rea--AxQAr";
export var ellipsis = "program-options-module--ellipsis--6vh4o";
export var ellipsis_1 = "program-options-module--ellipsis_1--6lhgn";
export var ellipsis_2 = "program-options-module--ellipsis_2---nONT";
export var ellipsis_3 = "program-options-module--ellipsis_3--t2mYe";
export var flex = "program-options-module--flex--hITQZ";
export var image = "program-options-module--image--LK3Rw";
export var image_wrapper = "program-options-module--image_wrapper--x1vX-";
export var title = "program-options-module--title--yp2lA";
export var wrapper = "program-options-module--wrapper--WZqqs";