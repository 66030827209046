import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import * as style from '../modules/footer.module.scss';
import Link from './utils/link';

function Footer({ data }) {
  const {
    footerMenuYaml: { items, app, community, stores },
  } = data;
  return (
    <footer className={style.Footer}>
      <div className="wrapper">
        <div className={style.FooterFlex}>
          {/* Community */}
          <div className={style.Community}>
            <h4 className={`t7 mb2`}>{community.title}</h4>
            <ul className={`${style.Social} mb3`}>
              {community.social.map(({ svg, link, tooltip }, key) => (
                <li key={key} className={style.Tootltip}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={tooltip}
                    href={link}
                    dangerouslySetInnerHTML={{ __html: svg }}
                  />
                  <span className={style.Tooltip_Text}>{tooltip}</span>
                </li>
              ))}
              <li className={style.Tootltip}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={community.facebook.tooltip}
                  href={community.facebook.link}
                >
                  <img src={community.facebook.svg.publicURL} width="40" alt={community.facebook.tooltip} />
                </a>
                <span className={style.Tooltip_Text}>{community.facebook.tooltip}</span>
              </li>
            </ul>
          </div>

          {/* App */}
          <div className={style.App}>
            <h4 className={`t7 mb1 mbmd2`}>{app.title}</h4>
            <div className={`p1 BlackLight mb3`} dangerouslySetInnerHTML={{ __html: app.content }} />

            <ul className={style.Stores}>
              {stores.map(({ link, svg }, key) => (
                <li key={key} className={`mb1`}>
                  <a target="_blank" rel="noopener noreferrer" href={link}>
                    <img alt={svg.publicURL} src={svg.publicURL} />
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Items */}
          <div className={style.Menu}>
            <div className="grid">
              {items.map((item, key) => (
                <div key={key} className={`grid__item`}>
                  <MenuItem {...item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

/**
 * Accordion: hide / show content on click
 */
function MenuItem({ items, title }) {
  const [state, setstate] = React.useState({
    open: false,
  });

  // Open / close
  function toggleOpen() {
    setstate({ open: !state.open });
  }

  const actions = {
    crisp: function () {
      window && window.$crisp && window.$crisp.push(['do', 'chat:open']);
    },
  };

  const list = items.map(({ title, link, extern, children, action }, j) => (
    <li key={j} className={`mb1 mbmd2`}>
      <Link
        className={`p0`}
        onClick={(evt) => {
          if (action) {
            evt.preventDefault();
            actions[action]();
          }
        }}
        to={link}
        extern={extern}
      >
        {title}
      </Link>
      {children && children.length > 0 && (
        <ul>
          {children.map((child, key) => (
            <li key={key} className={`mb1`}>
              {child.special ? (
                <a href={child.link} className={`p1 BlackLight`}>
                  {child.text}
                </a>
              ) : (
                <Link to={child.link} className={`p1 BlackLight`}>
                  {child.text}
                </Link>
              )}
            </li>
          ))}
        </ul>
      )}
    </li>
  ));

  return (
    <div className={state.open ? `${style.MenuItem} ${style.Active}` : style.MenuItem}>
      <button onClick={toggleOpen}>
        <span role="button" area-hidden="true" aria-label="toogle menu" />
        <span role="button" area-hidden="true" aria-label="toogle menu" />
      </button>

      <h4 className={`t7`}>{title}</h4>

      <ul>{list}</ul>
    </div>
  );
}

export default function (props) {
  return (
    <StaticQuery
      render={(data) => <Footer data={data} {...props} />}
      query={graphql`
        {
          footerMenuYaml {
            stores {
              svg {
                publicURL
              }
              link
            }
            legal {
              title
              link
              extern
            }
            items {
              title
              items {
                title
                link
                extern
                action
                children {
                  text
                  link
                  special
                }
              }
            }
            app {
              title
              content
            }
            community {
              title
              social {
                svg
                link
                extern
                tooltip
              }
              facebook {
                svg {
                  publicURL
                }
                link
                extern
                tooltip
              }
            }
          }
        }
      `}
    />
  );
}
