// extracted by mini-css-extract-plugin
export var button = "temoignages-module--button--6kzXO";
export var button__rotated = "temoignages-module--button__rotated--gHAqj";
export var diet = "temoignages-module--diet--yglNc";
export var diet_description = "temoignages-module--diet_description--H+VqI";
export var diet_name = "temoignages-module--diet_name--XwbLs";
export var diet_picture = "temoignages-module--diet_picture--Gj3FX";
export var diet_tags = "temoignages-module--diet_tags--wnGpM";
export var diet_wrapper = "temoignages-module--diet_wrapper--ed8f3";
export var diets = "temoignages-module--diets--ZtLVE";
export var flex = "temoignages-module--flex--t4X6r";
export var header = "temoignages-module--header--RukrP";
export var header_content = "temoignages-module--header_content--508bH";
export var header_img = "temoignages-module--header_img--oU5B7";
export var header_title = "temoignages-module--header_title--wBMxa";
export var header_title_wrapper = "temoignages-module--header_title_wrapper--STFUn";
export var section = "temoignages-module--section--XJLXA";
export var star_bottom = "temoignages-module--star_bottom--U0ul7";
export var tag = "temoignages-module--tag--QmREQ";
export var tag_wrapper = "temoignages-module--tag_wrapper--OIDxE";
export var testimonial = "temoignages-module--testimonial--fvCwX";
export var testimonial_avatar = "temoignages-module--testimonial_avatar--A3uHc";
export var testimonial_contents = "temoignages-module--testimonial_contents--Ckkl3";
export var testimonial_name = "temoignages-module--testimonial_name--mV7E0";
export var testimonial_quote = "temoignages-module--testimonial_quote--X7YXP";
export var testimonial_quote_bottom = "temoignages-module--testimonial_quote_bottom--Vn93L";
export var testimonials = "temoignages-module--testimonials--4QMYI";