// extracted by mini-css-extract-plugin
export var active = "navIcon-item-module--active--UqgK6";
export var icon1 = "navIcon-item-module--icon1--VhasD";
export var icon2 = "navIcon-item-module--icon2--FTw+H";
export var icon3 = "navIcon-item-module--icon3--gkt08";
export var iconContainer = "navIcon-item-module--iconContainer--NHPnj";
export var iconContainer1 = "navIcon-item-module--iconContainer1--RjJi9";
export var iconContainer2 = "navIcon-item-module--iconContainer2--v9dPw";
export var iconContainer3 = "navIcon-item-module--iconContainer3--9m1Uq";
export var overlay1 = "navIcon-item-module--overlay1--BJ0Kk";
export var previousActive = "navIcon-item-module--previousActive--3TrNR";