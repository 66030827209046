import React, { useState, useEffect, useRef, useContext } from 'react';

import * as styles from './podcast-audio-player.module.scss';
import { graphql, useStaticQuery } from 'gatsby';
import { AudioContext } from '../../context/AudioContext';

const AudioPlayer = ({ ...podcast }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(null);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const audioRef = React.useRef();
  const progressBarRef = useRef(null);

  const { playingAudio, handleAudioPlay } = useContext(AudioContext);

  const { play, pause } = useStaticQuery(graphql`
    query ImageQuery {
      play: file(relativePath: { eq: "podcast/play-icon.svg" }) {
        publicURL
      }
      pause: file(relativePath: { eq: "podcast/pause-icon.svg" }) {
        publicURL
      }
    }
  `);
  useEffect(() => {
    // Stop playback when component is unmounted or when audio being played changes
    return () => {
      if (audioRef.current && playingAudio === audioRef.current) {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    };
  }, [playingAudio]);

  const togglePlay = () => {
    if (!isPlaying) {
      audioRef.current.play();
      handleAudioPlay(audioRef.current);
    } else {
      audioRef.current.pause();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (isDragging) {
        const rect = progressBarRef.current.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const width = rect.width;
        const clickedValue = (x / width) * 100;
        const newCurrentTime = (duration / 100) * clickedValue;
        setCurrentTime(newCurrentTime);
        setProgressBarValue(clickedValue);
        audioRef.current.currentTime = newCurrentTime;
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [duration, isDragging]);

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
    setProgressBarValue((audioRef.current.currentTime / duration) * 100);
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  const handleProgressBarMouseDown = () => {
    setIsDragging(true);
  };

  const handleProgressBarMouseUp = () => {
    setIsDragging(false);
  };
  const handleMouseMove = (event) => {
    if (isDragging) {
      handleProgressBarMouseMove(event);
    }
  };

  const handleProgressBarClick = (event) => {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const width = rect.width;
    const clickedValue = (x / width) * 100;
    const newCurrentTime = (duration / 100) * clickedValue;
    setCurrentTime(newCurrentTime);
    setProgressBarValue(clickedValue);
    audioRef.current.currentTime = newCurrentTime;
  };

  const handleProgressBarMouseMove = (event) => {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const width = rect.width;
    const hoveredValue = (x / width) * 100;
    setProgressBarValue(hoveredValue);
  };

  const handleProgressBarMouseLeave = () => {
    setProgressBarValue((currentTime / duration) * 100);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const formatTimeinMinutes = (time) => {
    const minutes = Math.floor(time / 60);
    return `${minutes} min`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.play}>
        <audio
          ref={audioRef}
          src={podcast.podcast.audio_url.publicURL}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
        />

        <button className={styles.button} onClick={togglePlay}>
          <span className={styles.icon}>
            {isPlaying ? (
              <img src={pause.publicURL} className={styles.playbutton} alt="arrow" />
            ) : (
              <img src={play.publicURL} alt="arrow" />
            )}
          </span>
          <span className={styles.counter}>
            {' '}
            {isPlaying ? `${formatTime(currentTime)} / ${formatTime(duration)}` : formatTimeinMinutes(duration)}
          </span>
        </button>
      </div>
      {isPlaying && (
        <div>
          <div
            className={styles.progressBar}
            ref={progressBarRef}
            onMouseDown={handleProgressBarMouseDown}
            onMouseUp={handleProgressBarMouseUp}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleProgressBarMouseLeave}
          >
            <div
              className={styles.progressDot}
              style={{ left: `${progressBarValue}%` }}
              draggable="true"
              onDragStart={(e) => e.preventDefault()}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AudioPlayer;
