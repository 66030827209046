// extracted by mini-css-extract-plugin
export var black = "product-module--black--jAs0u";
export var contents_icon = "product-module--contents_icon--WaR5q";
export var contents_items = "product-module--contents_items--rn75A";
export var contents_single_item = "product-module--contents_single_item--Q95r4";
export var firstPlusIcon = "product-module--firstPlusIcon--KmNZY";
export var notification = "product-module--notification--K4Aif";
export var recButtonContainer = "product-module--recButtonContainer--DOytC";
export var recContent = "product-module--recContent--dKSoC";
export var recContentImage = "product-module--recContentImage--mS8+x";
export var recContentInfo = "product-module--recContentInfo--8K2yF";
export var recPlusIcon = "product-module--recPlusIcon--kLPo6";
export var recPlusIconContainer = "product-module--recPlusIconContainer--HU0qY";