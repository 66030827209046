import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import * as styles from './diets.module.scss';
import Link from '../utils/link';

function Diets() {
  const {
    pageNutritionnistesYaml: { diets },
  } = useStaticQuery(graphql`
    query DietsSection2Query {
      pageNutritionnistesYaml {
        diets {
          name
          tags {
            value
          }
          description
          picture {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);

  return (
    <section className={`${styles.section}`}>
      <div className={styles.diets}>
        <h2 className={styles.title}>Découvrez les coachs fertilité BeMum.</h2>
        <div className={styles.horizontalScroll}>
          {diets.map((diet, key) => (
            <Diet
              key={key}
              name={diet.name}
              tags={diet.tags}
              description={diet.description}
              picture={diet.picture.childImageSharp.gatsbyImageData}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

function Diet({ name, picture, tags, description }) {
  return (
    <div className={styles.diet}>
      <div className={styles.diet_picture}>
        <GatsbyImage image={picture} alt={name} />
      </div>
      <div className={styles.diet_text}>
        <h3 className={styles.diet_name}>{name}</h3>
        {tags && (
          <ul className={`${styles.tag_wrapper}`}>
            {tags.map((tag, i) => (
              <li key={i}>
                <span className={`Center Black Regular ${styles.tag}`}>{tag.value}</span>
              </li>
            ))}
          </ul>
        )}
        <p className={styles.diet_description}>{description}</p>
      </div>
    </div>
  );
}

export default Diets;
