import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { hasAnEdge } from '../../functions';
import * as style from './modules/rea.module.scss';

/**
 * Accompagnement BeMum
 */
function Component({ data }) {
  const { allStaticReaYaml } = data;
  const items = hasAnEdge(allStaticReaYaml) ? allStaticReaYaml.edges : [];
  return (
    <div className={`${style.Section}`}>
      <div className="wrapper">
        <div className="grid-uniform">
          {items.map((item, index) => (
            <div key={index} className={`grid__item cmd3 clg3 csm6 cxs6`}>
              <div className={`${style.Component} mb2 mbmd0`}>
                <div className={`${style.Component__Img} mb3`}>
                  <img src={item.node.img.publicURL} alt={item.node.title} />
                </div>
                <h3 className={`Light Center ${style.Component__Title}`}>{item.node.title}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default function (props) {
  return (
    <StaticQuery
      render={(data) => <Component data={data} {...props} />}
      query={graphql`
        {
          allStaticReaYaml {
            edges {
              node {
                img {
                  publicURL
                }
                title
              }
            }
          }
        }
      `}
    />
  );
}
