// extracted by mini-css-extract-plugin
export var button = "fixed-recap-module--button--gPHd9";
export var mobile = "fixed-recap-module--mobile--5E485";
export var recap = "fixed-recap-module--recap--RcoBU";
export var recap__fixed = "fixed-recap-module--recap__fixed--BmeNn";
export var recap__hidden__mobile = "fixed-recap-module--recap__hidden__mobile--jBLFC";
export var recap_btn = "fixed-recap-module--recap_btn--2oiNg";
export var recap_btn_rotated = "fixed-recap-module--recap_btn_rotated--6kQ7Q";
export var recap_content = "fixed-recap-module--recap_content--6KEj+";
export var recap_name = "fixed-recap-module--recap_name--Jb+sw";
export var recap_title = "fixed-recap-module--recap_title--wvp4i";
export var recap_wrapper__fixed = "fixed-recap-module--recap_wrapper__fixed--2mOzi";
export var subtotal = "fixed-recap-module--subtotal--pEuC4";