import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { FadeIn } from 'react-anim-kit';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import 'url-search-params-polyfill';
import Layout from '../components/layout';
import Accompagnement from '../components/static/acc';
import Reassurance from '../components/static/rea';
import SEO from '../components/seo';
import * as style from '../modules/profil.module.scss';
import '../modules/tabs.scss';

const hashmapUrls = {
  0: '/profil-age-fertilite/',
  1: '/profil-essais-bebe/',
  2: '/profil-poids-sopk-fertilite/',
};

function IndexPage({ pageContext }) {
  const { title, profils } = pageContext;
  const location = useLocation();

  const values = Object.values(hashmapUrls);
  const index = values.indexOf(location.pathname) || 0;

  const [toggleMore, setToggleMore] = React.useState(false);
  const toggleMoreClickHandler = React.useCallback(() => setToggleMore(!toggleMore), [toggleMore, setToggleMore]);
  const toggleMoreText = React.useMemo(() => `Voir ${toggleMore ? 'moins' : 'plus'}`, [toggleMore]);

  return (
    <Layout>
      <SEO
        title={profils[index].seo.title}
        description={profils[index].seo.meta}
        canonical={`https://www.bemum.co${location.pathname}/`}
      />

      <Tabs
        selectedIndex={index}
        onSelect={(index) => {
          navigate(hashmapUrls[index]);
        }}
      >
        <header id="Profil" className={`BgRedLighter ${style.Header}`}>
          <div className={`wrapper`}>
            <div className={`cmd9 clg9 mb5 mbmd8`}>
              <p className={`t1 mb2 mbmd3`}>{title}</p>
            </div>

            <TabList>
              {profils.map((profil, key) => (
                <Tab key={key}>
                  <div className={`Center p0`}>{profil.tab}</div>
                </Tab>
              ))}
            </TabList>
          </div>
        </header>
        <div>
          {profils.map((profil, key) => {
            return (
              <TabPanel key={key}>
                <FadeIn>
                  <div>
                    <div className={`${style.Profil}`}>
                      <div>
                        <div className={`cmd11 clg11`}>
                          <h1 className={`t2 mb3`}>{profil.title}</h1>
                          <div
                            className={`p1 ${style.Content} mbmd4 mb6`}
                            dangerouslySetInnerHTML={{ __html: profil.content }}
                          />
                        </div>
                      </div>
                      <div>
                        <GatsbyImage image={profil.image.childImageSharp.gatsbyImageData} alt={profil.title} />
                      </div>
                    </div>
                    <div className="mt6">
                      <Accompagnement />
                    </div>
                    {profil.tab !== '35 +' && (
                      <div className={`${style.ProfilShortLongContent}`}>
                        <div>
                          <div className={`cmd11 clg11`}>
                            <div
                              className={`p1 ${style.Content} mbmd4 ${!toggleMore ? style.GradientContent : ''}`}
                              dangerouslySetInnerHTML={{
                                __html: toggleMore ? profil.full_content : profil.short_content,
                              }}
                            />
                            <button className={`p0 ${style.toggleMoreButton} mt1 mb6`} onClick={toggleMoreClickHandler}>
                              {toggleMoreText}
                            </button>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    )}
                  </div>
                </FadeIn>
              </TabPanel>
            );
          })}
        </div>
      </Tabs>
      <Reassurance />
    </Layout>
  );
}

export default IndexPage;
