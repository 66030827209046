import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Layout from '../components/layout';
import Reassurance from '../components/static/rea';
import * as style from '../modules/referral.module.scss';
import oatsIcon from '../images/referral/008-oats.svg';
import vitaminsIcon from '../images/referral/004-vitamins.svg';
import yogaIcon from '../images/referral/007-yoga.svg';
import Avis from '../components/program-2024/avis';
import { useStaticQuery, graphql } from 'gatsby';
import Link from '../components/utils/link';
import HandBandItem from '../components/sopk/handband-item';

const ReferralSystem = () => {
  const isBrowser = typeof window !== 'undefined';

  const [sponsorEmail, setSponsorEmail] = useState('');
  const [registered, setRegistered] = useState(false);
  const [referredEmail, setReferredEmail] = useState('');
  const [referrals, setReferrals] = useState([]);
  const [error, setError] = useState('');
  const [showFullText, setShowFullText] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const handBandRef = useRef(null);
  const handBandContainerRef = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      setSponsorEmail(localStorage.getItem('sponsorEmail') || '');
      setRegistered(!!localStorage.getItem('sponsorEmail'));
    }
  }, [isBrowser]);

  const handleShowFullText = () => {
    setShowFullText(true);
  };

  useEffect(() => {
    if (registered && sponsorEmail) {
      fetchReferrals();
    }
  }, [registered, sponsorEmail]);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleRequestError = (error) => {
    if (error.response && error.response.data) {
      if (error.response.data.message === 'This email is not associated with a registered patient.') {
        setError("Cet email n'est pas associé à un patient enregistré.");
      } else {
        setError(error.response.data.message);
      }
    } else {
      setError('Une erreur est survenue. Veuillez réessayer.');
    }
  };

  const registerSponsor = async () => {
    if (!sponsorEmail) {
      setError('Veuillez entrer un email.');
      return;
    }

    if (!isValidEmail(sponsorEmail)) {
      setError('Veuillez entrer un email valide.');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.GATSBY_API}/referral/register`,
        { email: sponsorEmail },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (response.status === 201) {
        localStorage.setItem('sponsorEmail', sponsorEmail);
        setRegistered(true);
        setError('');
        fetchReferrals();
      }
    } catch (error) {
      handleRequestError(error);
    }
  };

  const fetchReferrals = async () => {
    try {
      const response = await axios.get(`${process.env.GATSBY_API}/referral`, {
        params: { sponsorEmail },
      });

      if (Array.isArray(response.data)) {
        setReferrals(response.data);
        setError('');
      } else {
        console.error('Data format incorrect', response.data);
        setReferrals([]);
      }
    } catch (error) {
      console.error('Failed to fetch referrals', error);
      setError('Failed to fetch referrals');
      setReferrals([]);
    }
  };

  const handleAddReferral = async () => {
    if (!referredEmail) {
      setError("Veuillez entrer l'email de l'ami.");
      return;
    }

    if (!isValidEmail(referredEmail)) {
      setError('Veuillez entrer un email valide.');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.GATSBY_API}/referral/create`,
        { sponsorEmail, referredEmail },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (response.status === 201) {
        fetchReferrals();
        setReferredEmail('');
        setError('');
      }
    } catch (error) {
      handleRequestError(error);
    }
  };

  const {
    pageProgramme2024Yaml: { testimonials, trustpilot_star, trustpilot_stars, quote_reverse_icon, right_arrow_icon },
    pageSopkYaml: { handBandItems },
  } = useStaticQuery(graphql`
    query {
      pageProgramme2024Yaml {
        testimonials {
          name
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          age
          tagLabel
          tags {
            value
          }
          program {
            value
          }
          testimonial
        }
        trustpilot_star
        trustpilot_stars {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        quote_reverse_icon {
          publicURL
        }
        right_arrow_icon {
          publicURL
        }
      }
      pageSopkYaml {
        handBandItems {
          image {
            publicURL
          }
          description
        }
      }
    }
  `);

  useEffect(() => {
    const handleScroll = () => {
      if (handBandRef.current) {
        const container = handBandRef.current;
        const scrollPosition = container.scrollLeft;
        const containerWidth = container.scrollWidth - container.offsetWidth;
        const scrollPercent = scrollPosition / containerWidth;
        const newIndex = Math.round(scrollPercent * 2);
        setActiveIndex(newIndex);
      }
    };

    const container = handBandRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (handBandContainerRef.current) {
        const container = handBandContainerRef.current;
        let newScrollPosition = container.scrollLeft + container.offsetWidth;

        if (newScrollPosition <= container.scrollWidth - container.offsetWidth) {
          container.scrollLeft = newScrollPosition;
        } else {
          setTimeout(() => {
            container.scrollLeft = 0;
          }, 3000);
        }
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Layout>
      <div className={style.headerSection}>
        <div className={style.headerContent}>
          <h1>Marrainez vos amies pour bénéficier</h1>
          <h2>de - 15 euros sur votre prochain mois de programme !</h2>
          <p>
            {showFullText ? (
              'Parce que vous êtes nos meilleures ambassadrices, faites profiter vos amies d’une réduction de 15 euros lors de leur inscription. En retour, vous recevrez également une réduction de 15 euros sur votre prochain mois d’abonnement. Ne manquez pas cette opportunité de soutenir vos amies dans leur parcours vers la maternité en partageant votre code de parrainage dès maintenant !'
            ) : (
              <>
                <span className={style.fullText}>
                  Parce que vous êtes nos meilleures ambassadrices, faites profiter vos amies d’une réduction de 15
                  euros lors de leur inscription. En retour, vous recevrez également une réduction de 15 euros sur votre
                  prochain mois d’abonnement. Ne manquez pas cette opportunité de soutenir vos amies dans leur parcours
                  vers la maternité en partageant votre code de parrainage dès maintenant !
                </span>
                <span className={style.partialText}>
                  Parce que vous êtes nos meilleures ambassadrices, faites profiter vos amies d’une réduction de 15
                  euros lors de leur inscription. En retour, vous recevrez également une réduction de 15 euros sur votre
                  prochain mois...
                  <button className={style.readMoreButton} onClick={handleShowFullText}>
                    lire plus
                  </button>
                </span>
              </>
            )}
          </p>

          {!registered ? (
            <div className={style.registerSection}>
              {error && <p className={style.error}>{error}</p>}
              <input
                type="email"
                placeholder="Votre email"
                value={sponsorEmail}
                onChange={(e) => setSponsorEmail(e.target.value)}
                className={style.emailInput}
              />
              <button onClick={registerSponsor} className={style.primaryButton}>
                Enregistrer
              </button>
            </div>
          ) : (
            <div className={style.referralSection}>
              {error && <p className={style.error}>{error}</p>}
              <input
                type="email"
                placeholder="Email de l'ami"
                value={referredEmail}
                onChange={(e) => setReferredEmail(e.target.value)}
                className={style.emailInput}
              />
              <button onClick={handleAddReferral} className={style.primaryButton}>
                Ajouter
              </button>

              <h2 className={style.referralSectionTitle}>Mes parrainages :</h2>
              <ul className={style.referralList}>
                {referrals.length > 0 ? (
                  referrals.map((referral, index) => (
                    <li key={index} className={style.referralItem} style={{ '--animation-delay': `${index * 0.15}s` }}>
                      {referral.referredEmail} - {referral.isPromoCodeUsed ? 'Parrainé' : 'En attente'}
                    </li>
                  ))
                ) : (
                  <li className={style.referralItem} style={{ '--animation-delay': '0s' }}>
                    Aucun parrainage trouvé
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>

      <div className={style.stepsSection}>
        <h2>
          Marrainez vos amies en <span className={style.highlight}>3 étapes</span> :
        </h2>
        <div className={style.stepsContainer}>
          <div className={style.stepBox}>
            <h3>1. Étape</h3>
            <p>Partagez votre code promo auprès de votre entourage.</p>
          </div>
          <div className={style.stepBox}>
            <h3>2. Étape</h3>
            <p>
              Votre amie s’inscrit au programme BeMum et bénéficie de 15 euros de réduction sur son premier mois
              d’abonnement.
            </p>
          </div>
          <div className={style.stepBox}>
            <h3>3. Étape</h3>
            <p>Une réduction de 15 euros est automatiquement appliquée sur votre prochain mois de programme !</p>
          </div>
        </div>
      </div>

      <div className={style.whyJoinSection}>
        <h1>Pourquoi rejoindre BeMum ?</h1>
        <h2>Le dispositif profertilite le plus complet du marché :</h2>
        <div className={style.whyJoinContainer} ref={handBandRef}>
          <div className={style.whyJoinBox}>
            <div className={style.whyJoinHeader}>
              <span className={style.whyJoinNumber}>01</span>
              <h3 className={style.whyJoinTitle}>Rééquilibrage alimentaire</h3>
            </div>
            <img src={oatsIcon} alt="Rééquilibrage alimentaire" className={style.whyJoinIcon} />
            <p className={style.whyJoinDescription}>
              Une application mobile avec des menus personnalisés pour suivre un régime alimentaire profertilite.
            </p>
          </div>
          <div className={style.whyJoinBox}>
            <div className={style.whyJoinHeader}>
              <span className={style.whyJoinNumber}>02</span>
              <h3 className={style.whyJoinTitle}>Boost nutritionnel</h3>
            </div>
            <img src={vitaminsIcon} alt="Boost nutritionnel" className={style.whyJoinIcon} />
            <p className={style.whyJoinDescription}>
              Des compléments alimentaires essentiels + des compléments additionnels spécifiques à votre profil.
            </p>
          </div>
          <div className={style.whyJoinBox}>
            <div className={style.whyJoinHeader}>
              <span className={style.whyJoinNumber}>03</span>
              <h3 className={style.whyJoinTitle}>Hygiène de vie</h3>
            </div>
            <img src={yogaIcon} alt="Hygiène de vie" className={style.whyJoinIcon} />
            <p className={style.whyJoinDescription}>Un accompagnement personnalisé avec une de nos coachs fertilité.</p>
          </div>
        </div>
        <div className={style.carouselIndicators}>
          <span className={activeIndex === 0 ? style.active : ''}></span>
          <span className={activeIndex === 1 ? style.active : ''}></span>
          <span className={activeIndex === 2 ? style.active : ''}></span>
        </div>
      </div>

      <div className={style.handBandContainer} ref={handBandContainerRef}>
        {handBandItems.map((item, index) => (
          <HandBandItem key={index} item={item} />
        ))}
      </div>
      <div className={style.avisSection}>
        <div className={style.avis}>
          <div className={style.trustpilot}>
            <Link to={`https://fr.trustpilot.com/review/bemum.co`} extern>
              <div className={style.trustpilotIcon} dangerouslySetInnerHTML={{ __html: trustpilot_star }} />
            </Link>
          </div>
        </div>
        <div className={style.avisCustom}>
          <Avis
            item={testimonials}
            trustpilot_stars={trustpilot_stars}
            quote={quote_reverse_icon}
            arrow={right_arrow_icon}
          />
        </div>
      </div>
      <Reassurance />
    </Layout>
  );
};

export default ReferralSystem;
