// extracted by mini-css-extract-plugin
export var abo = "age-module--abo--w0ahS";
export var active = "age-module--active--IQwvn";
export var arrowButton = "age-module--arrowButton--swo7-";
export var article_container = "age-module--article_container--j9fBk";
export var article_header = "age-module--article_header--lWzYp";
export var article_section = "age-module--article_section--dxuL1";
export var avis = "age-module--avis--QFDbd";
export var avisCustom = "age-module--avisCustom--fb78Y";
export var avisSection = "age-module--avisSection--TWjpV";
export var btn = "age-module--btn--3hkaZ";
export var buy = "age-module--buy--mKKEp";
export var buy_form = "age-module--buy_form--z+pZ+";
export var buy_img = "age-module--buy_img--PGgUN";
export var buy_inputs = "age-module--buy_inputs--WXLRe";
export var buy_inputs_details = "age-module--buy_inputs_details--i+PS-";
export var buy_inputs_price_text = "age-module--buy_inputs_price_text--vNXuW";
export var buy_inputs_subtitle = "age-module--buy_inputs_subtitle--7L6Zl";
export var buy_text = "age-module--buy_text--4ZmYZ";
export var buy_text_title = "age-module--buy_text_title--IVXkb";
export var carousel = "age-module--carousel--FJ8Yl";
export var carouselInner = "age-module--carouselInner--VpegP";
export var carouselItem = "age-module--carouselItem--Ns9HV";
export var carouselText = "age-module--carouselText--3dw8E";
export var composition = "age-module--composition--DY6vT";
export var custom_podcast_audio = "age-module--custom_podcast_audio--XOJs3";
export var custom_podcast_card = "age-module--custom_podcast_card--Nm62C";
export var custom_podcast_container = "age-module--custom_podcast_container--OPftA";
export var custom_podcast_img = "age-module--custom_podcast_img--rs34B";
export var custom_podcast_text = "age-module--custom_podcast_text--yGF0-";
export var error_msg = "age-module--error_msg--AEwt4";
export var firstPillar = "age-module--firstPillar--3V7QG";
export var firstPillarTitle = "age-module--firstPillarTitle--y57jb";
export var handBandContainer = "age-module--handBandContainer--80qMf";
export var header = "age-module--header--FpZGa";
export var headerButton = "age-module--headerButton--G2ZA-";
export var headerImage = "age-module--headerImage--JAfWU";
export var headerText = "age-module--headerText--wqYFc";
export var hovered = "age-module--hovered--x3w-s";
export var imageContainer = "age-module--imageContainer--pjL5K";
export var img_stars = "age-module--img_stars--1SmCR";
export var img_stars_buy = "age-module--img_stars_buy--6umfL";
export var informationSection = "age-module--informationSection--60RQc";
export var lastPillar = "age-module--lastPillar--fH++H";
export var list_buy = "age-module--list_buy--0o2EW";
export var logo_clarna_container = "age-module--logo_clarna_container--fQdno";
export var logo_klarna = "age-module--logo_klarna--3PzBd";
export var mainImage = "age-module--mainImage--wyUUt";
export var navigationArrows = "age-module--navigationArrows--6pYrm";
export var pack_mobile = "age-module--pack_mobile--4bGOn";
export var pack_price = "age-module--pack_price--y7O2o";
export var pillar = "age-module--pillar--jTtpp";
export var pillarImage = "age-module--pillarImage--mKWBw";
export var pillarTitle = "age-module--pillarTitle--6HqFa";
export var pillarsContainer = "age-module--pillarsContainer--BLqnv";
export var pillarsSection = "age-module--pillarsSection--nevX7";
export var pillarsTitle = "age-module--pillarsTitle--FVvAZ";
export var plusSymbol = "age-module--plusSymbol--o7in2";
export var podcast_container = "age-module--podcast_container--ZT35q";
export var podcast_header = "age-module--podcast_header--H5WsP";
export var podcast_section = "age-module--podcast_section--MGqg1";
export var radio_legende = "age-module--radio_legende--bLV4Z";
export var readMoreButton = "age-module--readMoreButton--1UWhL";
export var secondPillarTitle = "age-module--secondPillarTitle--fOYAQ";
export var selectedBackground = "age-module--selectedBackground--kd0mX";
export var sideImage = "age-module--sideImage--2Zbqn";
export var sideImages = "age-module--sideImages--KktnW";
export var socialPostImage = "age-module--socialPostImage--OIb+L";
export var socialPostLink = "age-module--socialPostLink--wgUWx";
export var socialPostsContainer = "age-module--socialPostsContainer--89Drd";
export var socialPostsSection = "age-module--socialPostsSection--t+LSn";
export var subSectionDescription = "age-module--subSectionDescription--LTwgY";
export var subSectionImage = "age-module--subSectionImage--Hn9W3";
export var subSectionImages = "age-module--subSectionImages--61oTl";
export var subSectionTitle = "age-module--subSectionTitle--8loXW";
export var subSections = "age-module--subSections--NBkaz";
export var thirdPillarTitle = "age-module--thirdPillarTitle--dpanM";
export var trustpilot = "age-module--trustpilot--xottT";
export var trustpilotIcon = "age-module--trustpilotIcon--M5Rq6";