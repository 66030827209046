import { graphql, navigate, StaticQuery } from 'gatsby';
import React from 'react';
import { dataEdge } from '../../functions';
import * as styles from './modules/program-description.module.scss';

/**
 * Le programme Bemum, au quotidien c'est...
 */
function Component({ data }) {
  const { allStaticProgramDescriptionYaml } = data;
  const {
    node: { title, subtitle, arrow, features },
  } = dataEdge(allStaticProgramDescriptionYaml);

  const handleClick = (link) => {
    const [pathname, hash] = link.split('#');
    navigate(pathname, { state: { hash: `#${hash}` } });
  };

  return (
    <section className={`${styles.section}`}>
      <div className={styles.title}>
        <h2 className={`t2`}>{title}</h2>
        <p className={`${styles.section_subtitle}`}>{subtitle}</p>
      </div>

      <div className={styles.features}>
        {features.map((item, key) => (
          <div key={key} className={`${styles.feature}`} onClick={() => handleClick(item.link)}>
            <div>
              <p className={`${styles.feature_number}`}>{item.number}</p>
              <p className={`${styles.feature_title}`}>{item.title}</p>
            </div>
            <div className={styles.feature_content_wrapper}>
              <img alt={item.alt} src={item.image.publicURL} className={`${styles.feature_image}`} />
              <p className={`${styles.feature_content}`}>{item.content}</p>
              <img alt={'arrow'} src={arrow.publicURL} className={`${styles.arrow}`} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default function (props) {
  return (
    <StaticQuery
      render={(data) => <Component data={data} {...props} />}
      query={graphql`
        {
          allStaticProgramDescriptionYaml {
            edges {
              node {
                title
                subtitle
                arrow {
                  publicURL
                }
                features {
                  number
                  title
                  content
                  alt
                  link
                  image {
                    publicURL
                  }
                }
              }
            }
          }
        }
      `}
    />
  );
}
