import React, { useState, useEffect } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import * as styles from '../components/module/checkout.module.scss';
import { Field } from 'formik';

function AddressAuto({ onAddress, ...props }) {
  const [fieldValue, setFieldValue] = useState('');

  const handleSelect = async (selectedAddress) => {
    const results = await geocodeByAddress(selectedAddress);
    const addressComponents = results[0].address_components;

    const get = (data) => addressComponents.find((v) => v.types[0] === data)?.long_name;
    const streetNumber = get('street_number');
    const route = get('route');
    const postalCode = get('postal_code');
    const city = get('locality');
    const country = get('country');

    setFieldValue(`${streetNumber || ''} ${route}`);

    onAddress({
      streetNumber,
      route,
      postalCode,
      city,
      country,
    });
  };

  return (
    <div>
      <PlacesAutocomplete
        value={fieldValue}
        onChange={setFieldValue}
        onSelect={handleSelect}
        searchOptions={{
          componentRestrictions: { country: ['fr', 'lu', 'be'] },
          fields: ['address_components', 'geometry'],
          types: ['address'],
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div className={styles.location_search_container}>
            <div
              {...getInputProps({
                style: { flex: 1, width: '100%' },
              })}
            >
              <Field {...props} value={fieldValue} key="address" />
            </div>

            <div className={styles.autocomplete_dropdown_container}>
              {suggestions.map((suggestion, id) => {
                const className = suggestion.active ? styles.suggestion_item_active : styles.suggestion_item;

                return (
                  <div
                    key={id}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <span>
                      {suggestion.description.length < 55
                        ? suggestion.description
                        : suggestion.description.slice(0, 5).concat('...')}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
}

export default AddressAuto;
