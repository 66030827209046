// extracted by mini-css-extract-plugin
export var composition_wrapper = "coaching-module--composition_wrapper--jnDXd";
export var contents_icon = "coaching-module--contents_icon--eQDfl";
export var contents_items = "coaching-module--contents_items--2Zah+";
export var contents_single_item = "coaching-module--contents_single_item--dDlRK";
export var details = "coaching-module--details--QapRy";
export var doubt = "coaching-module--doubt--acu7w";
export var flex = "coaching-module--flex--t1-Ef";
export var flex__reverse = "coaching-module--flex__reverse--QLnOF";
export var illustration = "coaching-module--illustration--Ks4aL";
export var illustration_wrapper = "coaching-module--illustration_wrapper--BYpw1";
export var image_wrapper = "coaching-module--image_wrapper--dyzBu";
export var image_wrapper__coaching = "coaching-module--image_wrapper__coaching--cRFho";
export var mt4 = "coaching-module--mt4--SeMn1";
export var mutuelle = "coaching-module--mutuelle--Y3LQw";
export var mutuelle_icon = "coaching-module--mutuelle_icon--IEVyO";
export var mutuelle_title = "coaching-module--mutuelle_title--3jQKS";
export var presentation_content = "coaching-module--presentation_content--WzPbN";
export var price = "coaching-module--price--m+8v2";
export var price_wrapper = "coaching-module--price_wrapper--IaKC0";
export var section = "coaching-module--section--0BX6I";
export var section__presentation = "coaching-module--section__presentation--lWxjZ";
export var stress_content = "coaching-module--stress_content--coJlh";
export var stress_content_2 = "coaching-module--stress_content_2--O24ru";
export var stress_section = "coaching-module--stress_section--nt685";
export var stress_title = "coaching-module--stress_title--nMu4e";