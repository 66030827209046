// extracted by mini-css-extract-plugin
export var feature = "diagnostic-page-module--feature--qiEwF";
export var feature_content = "diagnostic-page-module--feature_content--WS8IO";
export var feature_content_wrapper = "diagnostic-page-module--feature_content_wrapper--xf8Y2";
export var feature_image = "diagnostic-page-module--feature_image--kkHFN";
export var feature_number = "diagnostic-page-module--feature_number--dnsFV";
export var features = "diagnostic-page-module--features--Eb6L0";
export var header = "diagnostic-page-module--header--ryr4v";
export var header_mobile = "diagnostic-page-module--header_mobile--Nt5L+";
export var header_mobile_title = "diagnostic-page-module--header_mobile_title--br8O1";
export var modal_trigger = "diagnostic-page-module--modal_trigger--6guBU";
export var testimonial = "diagnostic-page-module--testimonial--WUvuy";
export var testimonial_avatar = "diagnostic-page-module--testimonial_avatar--FB76t";
export var testimonial_contents = "diagnostic-page-module--testimonial_contents--lS945";
export var testimonial_name = "diagnostic-page-module--testimonial_name--47NC8";
export var testimonial_quote = "diagnostic-page-module--testimonial_quote--yF9M3";
export var testimonial_text = "diagnostic-page-module--testimonial_text--3QZ30";
export var testimonial_wrapper = "diagnostic-page-module--testimonial_wrapper--67IXB";