import React from 'react';
import axios from 'axios';
function useFacebookConversionApi(
  event_name,
  event_source_url,
  client_email,
  client_firstname,
  client_phone,
  event_id,
  amount,
  content_ids
) {
  /**
   * Get IP address required by facebook conversion api
   * @see https://medium.com/how-to-react/how-to-get-user-ip-address-in-react-js-73eb295720d0
   */
  async function retrieveIdAddress() {
    const res = await axios.get('https://api64.ipify.org?format=json');
    return res.data.ip;
  }

  async function retrieveLocation() {
    /** https://ipapi.co/json/}  old api **/

    const res = await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.GATSBY_GEO_API_KEY} `);
    return res.data;
  }
  /**
   * @see https://stackoverflow.com/questions/10730362/get-cookie-by-name?page=1&tab=scoredesc#tab-top
   */
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  React.useEffect(() => {
    async function sendEventToFacebookConversionApi() {
      if (!event_name) return;
      const endpoint = `https://tcpw93qcre.execute-api.eu-west-3.amazonaws.com/default/sendEventsToFacebookConversionApi`;
      try {
        const client_ip_address = await retrieveIdAddress();
        const location = await retrieveLocation();
        return await axios({
          method: 'POST',
          url: endpoint,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            event_name: event_name,
            event_source_url: event_source_url,
            client_ip_address: client_ip_address,
            client_user_agent: window.navigator.userAgent,
            event_context: process.env.GATSBY_CONTEXT,
            client_email: client_email,
            client_firstname: client_firstname,
            client_country: location.country_code2 || 'FR',
            client_state: location.state_prov || 'Unknown',
            client_city: location.city || 'Unknown',
            client_zip: location.zipcode || 'Unknown',
            client_phone: client_phone,
            event_id: event_id,
            amount: amount,
            content_ids: content_ids,
            _fbp: getCookie('_fbp'),
            _fbc: getCookie('_fbc'),
          }),
        });
      } catch (error) {}
    }
    sendEventToFacebookConversionApi();
  }, [content_ids, event_name, event_id, event_source_url, client_email, client_phone, client_firstname]);

  return [];
}

export default useFacebookConversionApi;
