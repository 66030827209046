import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FadeIn } from 'react-anim-kit';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import 'url-search-params-polyfill';
import useLocalStorage from '../hooks/useLocalStorage';
import Layout from '../components/layout';
import Coaching from '../components/program/coaching';
import FoodSupplements from '../components/program/food-supplements';
import MealPlanning from '../components/program/meal-planning';
import SEO from '../components/seo';
import Reassurance from '../components/static/rea';
import CartContext, { products } from '../context/CartContext';
import * as style from '../modules/programme-essentiel.module.scss';
import useFacebookConversionApi from '../hooks/useFacebookConversionApi';
import '../modules/tabs.scss';

const hashmapUrls = {
  0: '/programme/programme-essentiel/0/',
  1: '/programme/programme-essentiel/1/',
  2: '/programme/programme-essentiel/2/',
};

function ProgrammeEssentiel({ pageContext }) {
  const { title, seo, description, img, button, rea, pillars } = pageContext;
  const location = useLocation();

  const values = Object.values(hashmapUrls);
  let index = values.indexOf(location.pathname);
  if (index === -1) index = 1;

  const { setCart } = useContext(CartContext);
  const tabContentRef = useRef(null);
  const bannerRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);
  const handleScroll = (e) => {
    if (!bannerRef.current || !tabContentRef.current) return;

    const bannerRefPos = bannerRef.current.getBoundingClientRect();
    const tabContentRefPos = tabContentRef.current.getBoundingClientRect();

    // Tab Container is above navbar (height: 80px) and banner
    const trigger = tabContentRefPos.top < bannerRefPos.height + 80 + 30;

    if (!isFixed && trigger) setIsFixed(true);
    if (!trigger && isFixed) setIsFixed(false);
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  });

  const [fertilityTestResults] = useLocalStorage('answers', null);
  const [eventId] = useLocalStorage('eventId', null);

  const [fbEventName, setFbEventName] = useState(null);

  // cf issue bemum#1638, price of Essentiel if 31,50€
  useFacebookConversionApi(
    fbEventName,
    location.href,
    fertilityTestResults?.email,
    fertilityTestResults?.firstname,
    fertilityTestResults?.phone,
    eventId,
    30
  );

  function addToCart() {
    setFbEventName('ViewContent');
    setCart('add', 'program', 'essentiel');
    navigate('/panier/');
  }

  return (
    <Layout>
      <SEO title={seo.title} description={seo.meta} canonical={seo.canonical} />
      <Tabs
        selectedIndex={index}
        onSelect={(index) => {
          navigate(hashmapUrls[index]);
        }}
      >
        <header className={`BgPurpleLighter`}>
          <div className={style.title}>
            <h1 dangerouslySetInnerHTML={{ __html: title }} />

            <div className={`${style.flex} ${style.price_wrapper}`}>
              <div className={style.image_wrapper}>
                <GatsbyImage image={img.childImageSharp.gatsbyImageData} alt={title} />
              </div>
              <div className={style.price_contents}>
                <div className={`${style.price} Uppercase p0`} dangerouslySetInnerHTML={{ __html: description }} />
                <button className={`Btn Btn--Black ${style.price_button}`} onClick={addToCart}>
                  {button}
                </button>
              </div>
            </div>

            <h2 className={`${style.rea} Underline t3`}>{rea}</h2>

            <div className={`${isFixed ? style.tab_list__fixed : ''}`} ref={bannerRef}>
              <TabList className={style.tab_list}>
                {pillars.map((pillar, key) => (
                  <Tab
                    key={key}
                    className={`react-tabs__tab ${style.tab_wrapper}`}
                    selectedClassName={`${style.tab_wrapper__selected}`}
                  >
                    <div className={`${style.tab}`}>
                      <img src={pillar.image.publicURL} className={style.tab_image} alt={pillar.alt} />
                      <span className={style.tab_title}>{pillar.tab}</span>
                    </div>
                  </Tab>
                ))}
              </TabList>
            </div>
          </div>
        </header>
        <div ref={tabContentRef}>
          <TabPanel key="0">
            <FadeIn>
              <MealPlanning />
            </FadeIn>
          </TabPanel>
          <TabPanel key="1">
            <FadeIn>
              <FoodSupplements />
            </FadeIn>
          </TabPanel>
          <TabPanel key="2">
            <FadeIn>
              <Coaching />
            </FadeIn>
          </TabPanel>
        </div>
      </Tabs>
      <Reassurance />
    </Layout>
  );
}

export default ProgrammeEssentiel;
