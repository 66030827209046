// extracted by mini-css-extract-plugin
export var diet = "diets-module--diet--oBuoz";
export var diet_description = "diets-module--diet_description--1ZkWt";
export var diet_name = "diets-module--diet_name--MVSKC";
export var diet_picture = "diets-module--diet_picture--5gnNV";
export var diet_tags = "diets-module--diet_tags--+vr5F";
export var diet_text = "diets-module--diet_text--XkJTB";
export var diets = "diets-module--diets--QFzgg";
export var horizontalScroll = "diets-module--horizontalScroll--gXlmo";
export var section = "diets-module--section--SRQiv";
export var tag = "diets-module--tag--I8FhD";
export var tag_wrapper = "diets-module--tag_wrapper--8O7-9";
export var title = "diets-module--title--7Fl-6";