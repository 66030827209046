// extracted by mini-css-extract-plugin
export var animatetop = "flyer-prescription-module--animatetop--+pMUJ";
export var arrow = "flyer-prescription-module--arrow--5kvoP";
export var close = "flyer-prescription-module--close--lvZVp";
export var contact_us = "flyer-prescription-module--contact_us--tUhZA";
export var content = "flyer-prescription-module--content--I6Jbm";
export var ctaButton = "flyer-prescription-module--ctaButton--iQkSk";
export var ctaIcon = "flyer-prescription-module--ctaIcon--agAlU";
export var ctaText = "flyer-prescription-module--ctaText--kJ+W1";
export var discover = "flyer-prescription-module--discover--+lq3-";
export var flyerFooter = "flyer-prescription-module--flyerFooter--fdHmz";
export var inputContainer = "flyer-prescription-module--inputContainer--oO3mC";
export var link = "flyer-prescription-module--link--uhHfE";
export var logo = "flyer-prescription-module--logo--KX+4S";
export var phone = "flyer-prescription-module--phone--WBIbv";
export var popup = "flyer-prescription-module--popup--7wIWD";
export var rdv = "flyer-prescription-module--rdv--o7yzX";