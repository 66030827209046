// extracted by mini-css-extract-plugin
export var Active = "footer-module--Active--lwq0U";
export var App = "footer-module--App--sxlfW";
export var Community = "footer-module--Community--FkVUi";
export var Footer = "footer-module--Footer--9vbWc";
export var FooterFlex = "footer-module--FooterFlex--3tB5J";
export var Legal = "footer-module--Legal--3fNVz";
export var Menu = "footer-module--Menu--o5eMl";
export var MenuItem = "footer-module--MenuItem--I4vsV";
export var Mobile = "footer-module--Mobile--1N-zK";
export var Social = "footer-module--Social--iwSW6";
export var Stores = "footer-module--Stores--AcGk5";
export var Tooltip_Text = "footer-module--Tooltip_Text--wHY1-";
export var Tootltip = "footer-module--Tootltip--81E8C";