import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useContext, useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Link from '../../components/utils/link';
import CartContext from '../../context/CartContext';
import * as style from '../../modules/404.module.scss';
import useFacebookConversionApi from '../../hooks/useFacebookConversionApi';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useLocation } from '@reach/router';

function Success(props) {
  const { cart, setCart } = useContext(CartContext);
  const location = useLocation();
  const [fertilityTestResults] = useLocalStorage('answers', null);
  /**
   * Set by google tag manager
   * Used by Facebook Business Manager to deduplicate events
   * @see https://www.facebook.com/business/help/823677331451951?id=1205376682832142
   */
  const contentIds = cart?.contents?.items.map((item) => item.id) || [];
  const [eventId] = useLocalStorage('eventId', null);
  useFacebookConversionApi(
    'PageView',
    location.href,
    fertilityTestResults?.email,
    fertilityTestResults?.firstname,
    fertilityTestResults?.phone,
    eventId
  );
  useFacebookConversionApi(
    'Purchase',
    location.href,
    fertilityTestResults?.email,
    fertilityTestResults?.firstname,
    fertilityTestResults?.phone,
    eventId,
    cart.total,
    contentIds
  );

  useEffect(() => {
    setCart('reset');
  });

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "checkout-success/bedaddy.jpeg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <Layout {...props}>
      <SEO
        title="Souscription validée"
        description="Votre souscription a été bien prise en compte. Vous allez recevoir un récapitulatif par email."
        noindex
      />
      <div id={style.NotFound}>
        <div className="wrapper">
          <h1 className="t1 mb3">Souscription validée</h1>
          <p className="p0">
            Votre souscription a été bien prise en compte. Vous allez recevoir un récapitulatif par email.
          </p>
          <p className="p0 mb2">Merci de votre confiance.</p>
          <Link to={`/`} className={`Btn Btn--Reg`}>
            Retour à l'accueil
          </Link>
        </div>

        <div className={`${style.bedaddy} wrapper`}>
          <h3 className={style.bedaddy_title}>À deux c’est mieux !</h3>
          <div className={style.bedaddy_flex}>
            <div>
              <p className={style.bedaddy_text}>
                Doublez vos chances de tomber enceinte et suivez les programmes BeMum et BeDaddy en même temps avec
                votre compagnon !
              </p>
              <div className={`${style.bedaddy_button_wrapper}`}>
                <Link to={`https://www.bedaddy.fr/`} extern className={`Btn ${style.bedaddy_button}`}>
                  Découvrez BeDaddy !
                </Link>
              </div>
            </div>
            <GatsbyImage
              image={data.file.childImageSharp.gatsbyImageData}
              className={style.bedaddy_image}
              alt="beddady"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Success;
