// extracted by mini-css-extract-plugin
export var avis = "avis-module--avis--GQilC";
export var avis_author = "avis-module--avis_author--IiQVG";
export var bar_avis = "avis-module--bar_avis--nOSwM";
export var closing_quote = "avis-module--closing_quote--BxFvl";
export var h3 = "avis-module--h3--C5rTH";
export var header_avis = "avis-module--header_avis--f5AmM";
export var img_avis = "avis-module--img_avis--C6c4O";
export var img_stars = "avis-module--img_stars--cAqg4";
export var opening_quote = "avis-module--opening_quote--oEEG1";
export var texte = "avis-module--texte--7AaBe";