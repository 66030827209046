import React from 'react';

import * as style from './modules/ellipses.module.scss';

export default function ({ top, left, bottom, right, rotate }) {
  return (
    <div className={`${style.elipses_wrapper}`} style={{ top: top, left: left, bottom: bottom, right: right }}>
      <div className={`${style.content_elipses} ${rotate ? `${style.rotate}` : ''}`}>
        <div className={style.elipse}></div>
        <div className={`${style.elipse} ${style.elipse_2}`}></div>
      </div>
    </div>
  );
}
