import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Link from '../utils/link';

import { dataEdge } from '../../functions';
import * as style from './modules/acc.module.scss';

/**
 * Accompagnement BeMum
 */
function Component({ data, wide }) {
  const { allStaticAccYaml } = data;
  const { node } = dataEdge(allStaticAccYaml);
  const grid = wide ? 'grid__item' : 'grid__item cmd10 clg10 shmd1 shlg1';
  return (
    <div className={style.Section}>
      <div className="wrapper">
        <div className="grid">
          <div className={grid}>
            <div className={`${style.Component}`}>
              <div className={`${style.Component__Img} mb4`}>
                <svg width="54" height="55" viewBox="0 0 54 55">
                  <g
                    stroke="#1D1D1B"
                    strokeWidth="1.5"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M12.533 54.164H3.874A2.875 2.875 0 011 51.289V31.81a2.875 2.875 0 012.874-2.874h8.659a2.874 2.874 0 012.874 2.874v19.48a2.874 2.874 0 01-2.874 2.875zM31.258 54.164h-8.659a2.875 2.875 0 01-2.874-2.875V24.054a2.875 2.875 0 012.874-2.874h8.659a2.874 2.874 0 012.874 2.874v27.235a2.874 2.874 0 01-2.874 2.875zM49.927 54.164H41.27a2.875 2.875 0 01-2.875-2.875V17.591a2.875 2.875 0 012.875-2.874h8.658a2.874 2.874 0 012.875 2.874v33.698a2.874 2.874 0 01-2.875 2.875zM9.18 19.166L22.908 5.437l8.74 8.74L44.761 1.064" />
                    <path d="M35.591 1h9.235v9.235" />
                  </g>
                </svg>
              </div>
              <h2 className={`t2 mb2 Center DeepBlack`}>{node && node.title}</h2>
              {node && <div className={`p0 mb4 Center`} dangerouslySetInnerHTML={{ __html: node.content }} />}
              <Link to={node && node.button.link} className={`Btn Btn--Reg Btn--Diag DeepBlack`}>
                {node && node.button.text}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function (props) {
  return (
    <StaticQuery
      render={(data) => <Component data={data} {...props} />}
      query={graphql`
        {
          allStaticAccYaml {
            edges {
              node {
                title
                content
                button {
                  link
                  text
                }
              }
            }
          }
        }
      `}
    />
  );
}
