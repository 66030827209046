// extracted by mini-css-extract-plugin
export var bounce = "programme-essentiel-module--bounce--coyy9";
export var flex = "programme-essentiel-module--flex--MgCAR";
export var image_wrapper = "programme-essentiel-module--image_wrapper--fUKG6";
export var price = "programme-essentiel-module--price--HBtvk";
export var price_button = "programme-essentiel-module--price_button--mi5Ql";
export var price_contents = "programme-essentiel-module--price_contents--6Un+M";
export var price_wrapper = "programme-essentiel-module--price_wrapper--4DXoj";
export var rea = "programme-essentiel-module--rea--sDqka";
export var tab = "programme-essentiel-module--tab--hgARN";
export var tab_image = "programme-essentiel-module--tab_image--5klKA";
export var tab_list = "programme-essentiel-module--tab_list--Zudxq";
export var tab_list__fixed = "programme-essentiel-module--tab_list__fixed--CZx22";
export var tab_title = "programme-essentiel-module--tab_title--OXu9C";
export var tab_wrapper = "programme-essentiel-module--tab_wrapper--2xe8R";
export var tab_wrapper__selected = "programme-essentiel-module--tab_wrapper__selected--vrQqh";
export var title = "programme-essentiel-module--title--kjzPu";